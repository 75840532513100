import React from "react";
import { grey } from "@mui/material/colors";
import Heading from "common/components/Heading";
import { PersonOrgForm } from "common/components/PersonOrgForm";

const AddInstaller = () => {
  return (
    <div>
      <div>
        <div>
          <Heading
            variant={"h3"}
            title={"New Installer"}
            sxProp={{ fontWeight: 800, m: 2, p: 2, color: grey[600] }}
          />
        </div>
        <PersonOrgForm id="" formName={"installer"} />
      </div>
    </div>
  );
};

export default AddInstaller;
