import axios from "axios";

const config = (authToken, signal) => {
  return {
    headers: {
      "Content-type": "application/json;charset=UTF-8",
      Authorization: "Bearer " + String(authToken),
    },
    signal,
  };
};
const configWithoutAuthToken = (signal) => {
  return {
    headers: {
      "Content-type": "application/json;charset=UTF-8",
    },
    signal,
  };
};

const api_service = {
  async sendMessage(formData) {
    return await axios.post("/api/view/publicMessage", formData);
  },
  async getTotalJobs(signal) {
    return await axios.get(
      "/api/view/getTotalJobs",
      configWithoutAuthToken(signal)
    );
  },

  async updatePwd(authToken, form) {
    return await axios.post(
      "/api/account/changePassword",
      form,
      config(authToken)
    );
  },
  async getUserById(authToken, userId, signal) {
    return await axios.get(
      "/api/admin/getUserById?userId=" + userId,
      config(authToken, signal)
    );
  },
  async getAllUsers(authToken, signal) {
    return await axios.get("/api/admin/getAllUsers", config(authToken, signal));
  },
  async updateUser(authToken, formData) {
    return await axios.put(
      "/api/admin/updateUser",
      formData,
      config(authToken)
    );
  },
  async registerUser(authToken, formData) {
    return await axios.post(
      "/api/view/registerUser",
      formData,
      config(authToken)
    );
  },
  async resetPassword(email) {
    return await axios.post("/api/view/resetPassword?email=" + email);
  },
  //----Dashboard---------------
  async getStats(authToken, year, signal) {
    return await axios.get(
      "/api/dashboard/statistics?year=" + year,
      config(authToken, signal)
    );
  },
  async getGlassJobs(authToken, signal, page, pageSize) {
    return await axios.get(
      "/api/dashboard/getGlassJobs?page=" + page +"&size=" + pageSize,
      config(authToken, signal)
    );
  },
  //----------End Dashboard--------

  //----------User----------------

  async getUser(user, authToken, signal) {
    return await axios.get(
      "/api/dashboard/getUser?user=" + user,
      config(authToken, signal)
    );
  },

  //---------End User-----------------

  //-------Comment API----------------

  async saveComment(authToken, formData) {
    return await axios.post(
      "/api/comment/addComment",
      formData,
      config(authToken)
    );
  },

  async updateComment(authToken, formData) {
    return await axios.put(
      "/api/comment/updateComment",
      formData,
      config(authToken)
    );
  },

  async getScheduleInfo(authToken, scheduleId, signal) {
    return await axios.get(
      "/api/comment/getCommentById?scheduleId=" + scheduleId,
      config(authToken, signal)
    );
  },

  async getAllComments(authToken, signal) {
    return await axios.get(
      "/api/comment/getAllComments",
      config(authToken, signal)
    );
  },

  async deleteComment(authToken, commentId) {
    return await axios.delete(
      "/api/comment/deleteComment?commentId=" + commentId,
      config(authToken)
    );
  },

  //------End Comment-------------------------

  //---------Job API---------------------------

  async getBuilderJobs(authToken, builderId, page, size, signal) {
    return await axios.get(
      "/api/job/getBuilderJobs?page=" +
        page +
        "&size=" +
        size +
        "&builderId=" +
        builderId,
      config(authToken, signal)
    );
  },
  async updatePaidStatus(authToken, jobIds) {
    return await axios.put(
      "/api/job/updatePaidStatus",
      jobIds,
      config(authToken)
    );
  },

  async searchJobByUserInput(authToken, formData, page, size, jobStatus) {
    return await axios.post(
      "/api/job/searchJobByUserInput?page=" +
        page +
        "&size=" +
        size +
        "&jobStatus=" +
        jobStatus,
      formData,
      config(authToken)
    );
  },

  async getJobByFieldName(authToken, fieldName, page, size, order, jobStatus) {
    return await axios.get(
      "/api/job/sortJobByFieldName?fieldName=" +
        fieldName +
        "&page=" +
        page +
        "&size=" +
        size +
        "&order=" +
        order +
        "&jobStatus=" +
        jobStatus,
      config(authToken)
    );
  },
  async updateJobStatus(authToken, jodIds, status) {
    return await axios.put(
      "/api/job/updateJobStatus?status=" + status,
      jodIds,
      config(authToken)
    );
  },
  async addJob(authToken, formData) {
    return await axios.post("/api/job/addJob", formData, config(authToken));
  },

  async updateJob(authToken, jobId, formData) {
    return await axios.put(
      "/api/job/updateJob?jobId=" + jobId,
      formData,
      config(authToken)
    );
  },
  async deleteJob(authToken, jobIds) {
    return await axios.delete(
      "/api/job/deleteJob?jobIds=" + jobIds,
      config(authToken)
    );
  },

  async getJobById(authToken, jobId, signal) {
    return await axios.get(
      "/api/job/getJobById?jobId=" + jobId,
      config(authToken, signal)
    );
  },

  async getJobs(authToken, page, size, jobStatus, signal) {
    return await axios.get(
      "/api/job/allJobs?page=" +
        page +
        "&size=" +
        size +
        "&jobStatus=" +
        jobStatus,
      config(authToken, signal)
    );
  },

  //-----End Job-------------------------------

  async getPaymentInfo(authToken, signal) {
    return await axios.get(
      "/api/payment/paymentInfo",
      config(authToken, signal)
    );
  },

  async getInstallerWorkInfo(authToken, signal, installerId) {
    return await axios.get(
      "/api/payment/getInstallerWorkInfoById?installerId=" + installerId,
      config(authToken, signal)
    );
  },

  //---------Installer API-----------------------
  async getInstallers(authToken, signal) {
    return await axios.get(
      "/api/installer/allInstaller",
      config(authToken, signal)
    );
  },
  async deleteInstaller(authToken, installerId) {
    return await axios.delete(
      "/api/installer/deleteInstaller?installerId=" + installerId,
      config(authToken)
    );
  },
  async addInstaller(authToken, formData) {
    return await axios.post(
      "/api/installer/addInstaller",
      formData,
      config(authToken)
    );
  },
  async updateInstaller(authToken, installerId, formData) {
    return await axios.put(
      "/api/installer/updateInstaller?installerId=" + installerId,
      formData,
      config(authToken)
    );
  },
  async getInstallerById(authToken, installerId, signal) {
    return await axios.get(
      "/api/installer/getInstallerById?installerId=" + installerId,
      config(authToken, signal)
    );
  },

  //--------End Installer-------------------

  //---------Builder API--------------------
  async getBuilders(authToken, signal) {
    return await axios.get(
      "/api/builder/allBuilder",
      config(authToken, signal)
    );
  },
  async deleteBuilder(authToken, builderId) {
    return await axios.delete(
      "/api/builder/deleteBuilder?builderId=" + builderId,
      config(authToken)
    );
  },
  async addBuilder(authToken, formData) {
    return await axios.post(
      "/api/builder/addBuilder",
      formData,
      config(authToken)
    );
  },
  async updateBuilder(authToken, builderId, formData) {
    return await axios.put(
      "/api/builder/updateBuilder?builderId=" + builderId,
      formData,
      config(authToken)
    );
  },
  async getBuilderById(authToken, builderId, signal) {
    return await axios.get(
      "/api/builder/getBuilderById?builderId=" + builderId,
      config(authToken, signal)
    );
  },

  //--------End Builder-------------------

  //-------Supplier API-------------------
  async getSuppliers(authToken, signal) {
    return await axios.get(
      "/api/supplier/allSuppliers",
      config(authToken, signal)
    );
  },
  async deleteSupplier(authToken, supplierId) {
    return await axios.delete(
      "/api/supplier/deleteSupplier?supplierId=" + supplierId,
      config(authToken)
    );
  },
  async getSupplierById(authToken, supplierId, signal) {
    return await axios.get(
      "/api/supplier/getSupplierById?supplierId=" + supplierId,
      config(authToken, signal)
    );
  },

  async addSupplier(authToken, formData) {
    return await axios.post(
      "/api/supplier/addSupplier",
      formData,
      config(authToken)
    );
  },
  async updateSupplier(authToken, supplierId, formData) {
    return await axios.put(
      "/api/supplier/updateSupplier?supplierId=" + supplierId,
      formData,
      config(authToken)
    );
  },
  //---------End Supplier------------------

  async retrieveAddress(address) {
    return await axios.get(
      `https://geocode.arcgis.com/arcgis/rest/services/World/GeocodeServer/suggest?text=${address}&countryCode=CA&f=json`
    );
  },
  async retrieveFullAddress(address) {
    return await axios.get(
      `https://geocode.arcgis.com/arcgis/rest/services/World/GeocodeServer/findAddressCandidates?SingleLine=${address}&countryCode=CA&f=json&outFields=*`
    );
  },
};

export default api_service;
