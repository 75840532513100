import React from "react";
import { Paper, TextField, Tooltip, IconButton, Grid } from "@mui/material";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import AppButton from "common/components/Button";
import { grey, red } from "@mui/material/colors";
import Heading from "common/components/Heading";
import Error from "common/components/Error";
import { AddBox } from "@mui/icons-material";
const ItemInfo = ({
  title,
  handleMultiInputChange,
  formData,
  handleRemoveClick,
  handleAddItemClick,
  errors,
  multiErrors,
}) => {
  return (
    <div>
      <Paper elevation={3} sx={{ m: 1, p: 4 }}>
        <Grid container spacing={0}>
          <Grid item xs={12} md={12}>
            <div>
              <Heading
                variant={"h5"}
                title={title}
                sxProp={{
                  m: 2,
                  color: grey[500],
                  textTransform: "uppercase",
                  fontSize: "12pt",
                }}
              />
            </div>
          </Grid>
        </Grid>
        <Error text={errors?.message} />
        <Grid container spacing={2}>
          <Grid item xs={12} md={12}>
            {formData?.items?.map((x, i) => {
              return (
                <Grid container key={i} spacing={2}>
                  <Grid item xs={4}>
                    <TextField
                      id="itemName"
                      fullWidth
                      color="success"
                      label="Item Name"
                      autoComplete="off"
                      variant="filled"
                      name="name"
                      value={x.name || ""}
                      margin="normal"
                      onChange={(e) => handleMultiInputChange(e, i)}
                    />
                    <div>
                      <Error text={multiErrors[i]?.name} />
                    </div>
                  </Grid>
                  <Grid item xs={4}>
                    <TextField
                      color="success"
                      id="unit_price"
                      label="Unit Price ($)"
                      margin="normal"
                      autoComplete="off"
                      fullWidth
                      inputProps={{ maxLength: 10 }}
                      variant="filled"
                      name="unitPrice"
                      value={x.unitPrice || ""}
                      onChange={(e) => handleMultiInputChange(e, i)}
                    />
                    <div>
                      <Error text={multiErrors[i]?.unitPrice} />
                    </div>
                  </Grid>
                  {i > 0 && (
                    <Grid item xs={4}>
                      <Tooltip title="Delete">
                        <IconButton
                          sx={{ mt: 4 }}
                          aria-label="delete"
                          onClick={(e) => handleRemoveClick(e, i)}
                        >
                          <CloseOutlinedIcon sx={{ color: red[500] }} />
                        </IconButton>
                      </Tooltip>
                    </Grid>
                  )}
                </Grid>
              );
            })}
          </Grid>
          <Grid item xs={12} sx={{ p: 0 }}>
            <AppButton
              icon={<AddBox />}
              btnValue={"Add"}
              type="button"
              variant={"contained"}
              handleClicked={handleAddItemClick}
            ></AppButton>
          </Grid>
        </Grid>
      </Paper>
    </div>
  );
};

export default ItemInfo;
