import React from "react";
import { useParams } from "react-router-dom";
import { green, grey } from "@mui/material/colors";
import Heading from "common/components/Heading";
import { PersonOrgForm } from "common/components/PersonOrgForm";

const EditInstaller = () => {
  const { installerId } = useParams();

  return (
    <div>
      <div>
        <div>
          <Heading
            variant={"h3"}
            title={"Edit Installer"}
            sxProp={{ fontWeight: 800, m: 2, p: 2, color: grey[600] }}
          />
        </div>
        <PersonOrgForm id={installerId} formName={"installer"} />
      </div>
    </div>
  );
};

export default EditInstaller;
