import React from "react";
import { Outlet } from "react-router-dom";
import Sidebar from "sidebar/components/Sidebar";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import { amber, green, grey } from "@mui/material/colors";

const theme = createTheme({
  palette: {
    mode: "light",
    primary: {
      main: "#FBFBFB",
    },
    secondary: {
      main: green[500],
    },
  },
  typography: {
    fontFamily: ["Roboto", '"Helvetica Neue"', "sans-serif"],
    fontSize: 12,
  },
  spacing: 4,
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          "&:hover": {
            backgroundColor: "#fbc02d",
            color: grey[900],
          },
        },
      },
    },
  },
});

const RootLayout = () => {
  return (
    <>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <div className="w-full flex">
          <nav>
            <Sidebar />
          </nav>
          <main>
            <Outlet />
          </main>
        </div>
      </ThemeProvider>
    </>
  );
};

export default RootLayout;
