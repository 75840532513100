import React, { useState, useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import {
  Grid,
  TextField,
  Paper,
  IconButton,
  Tooltip,
  Checkbox,
} from "@mui/material";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import SaveAltIcon from "@mui/icons-material/SaveAlt";
import Service from "common/utils/Common";
import SelectOptions from "common/components/Select";
import AuthContext from "context/AuthContext";
import AppButton from "common/components/Button";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import api_service from "common/utils/Api";
import Heading from "common/components/Heading";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import dayjs from "dayjs";
import { red, grey } from "@mui/material/colors";
import Error from "common/components/Error";
import { multiErrorsCheckForLevels } from "common/utils/MultiLineError";
import { responseErrorMsg } from "common/utils/ResponseError";
import AppContext from "context/AppContext";
import { AddBox } from "@mui/icons-material";

const JobForm = ({ jobId }) => {
  const { authTokens, logoutUser } = useContext(AuthContext);
  const { setIsLoading } = useContext(AppContext);
  const navigate = useNavigate();
  const [installers, setInstallers] = useState([]);
  const [suppliers, setSuppliers] = useState([]);
  const [supplierItems, setSupplierItems] = useState([]);
  const [multiLineErrors, setMultiLineErrors] = useState([]);
  const [errors, setErrors] = useState({});
  const [builders, setBuilders] = useState([]);

  const [form, setForm] = useState({
    builder: {},
    quote: "",
    paidAtm: "",
    installationFee: "",
    glassExpense: "",
    spindleExpense: "",
    woodExpense: "",
    miscExpense: "",
    subTotal: 0,
    jobDate: "",
    tax: 5,
    taxAmount: 0,
    total: 0,
    note: "",
    jobStatus: "",
    jobAddress: "",
    outstandingAtm: "",
    isGlassInd: false,
    jobDetails: [
      // {
      //   supplier: {},
      //   jobDetail: [{ description: {}, quantity: "", rate: "", amount: "" }],
      // },
    ],
    installers: [{}],
  });

  useEffect(() => {
    setForm({ ...form, outstandingAtm: calculateOutstandingBalance() });
  }, [form.quote, form.paidAtm]);

  useEffect(() => {
    const controller = new AbortController();
    const signal = controller.signal;
    if (jobId) getJobById(jobId, signal);

    getInstallers(signal);
    getSuppliers(signal);
    getBuilders(signal);
    return () => {
      controller.abort();
    };
  }, []);

  const getJobById = async (jobId, signal) => {
    try {
      setIsLoading(true);
      const response = await api_service.getJobById(authTokens, jobId, signal);

      if (response.status === 200) {
        setIsLoading(false);
        setForm(response.data);
      }
    } catch (error) {
      setIsLoading(false);
      if (error?.name !== "CanceledError") {
        setErrors(responseErrorMsg(error.response, logoutUser));
      }
    }
  };

  const getBuilders = async (signal) => {
    try {
      setIsLoading(true);
      const response = await api_service.getBuilders(authTokens, signal);
      if (response.status === 200) {
        setIsLoading(false);
        setBuilders(response.data);
      }
    } catch (error) {
      setIsLoading(false);
      if (error?.name !== "CanceledError") {
        setErrors(responseErrorMsg(error.response, logoutUser));
      }
    }
  };

  const getInstallers = async (signal) => {
    try {
      setIsLoading(true);
      const response = await api_service.getInstallers(authTokens, signal);
      if (response.status === 200) {
        setIsLoading(false);
        setInstallers(response.data);
      }
    } catch (error) {
      setIsLoading(false);
      if (error?.name !== "CanceledError") {
        setErrors(responseErrorMsg(error.response, logoutUser));
      }
    }
  };

  const getSuppliers = async (signal) => {
    try {
      setIsLoading(true);
      const response = await api_service.getSuppliers(authTokens, signal);

      if (response.status === 200) {
        setIsLoading(false);
        response.data.forEach((i) => {
          supplierItems[i.id] = i.items;
          setSupplierItems(supplierItems);
        });

        setSuppliers(response.data);
      }
    } catch (error) {
      setIsLoading(false);
      if (error?.name !== "CanceledError") {
        setErrors(responseErrorMsg(error.response, logoutUser));
      }
    }
  };

  const handleMultiWorkerChange = (e, i) => {
    setErrors([]);
    setMultiLineErrors([]);
    const { name, value } = e.target;
    const formData = { ...form };
    formData.installers[i] = value;
    setForm(formData);
  };

  const handleMultiSupplierChange = (e, i) => {
    setErrors([]);
    setMultiLineErrors([]);
    const { name, value } = e.target;
    const formData = { ...form };

    formData.jobDetails[i].supplier = value;

    setForm(formData);
  };

  const handleMultiJobDetailChange = (e, supplierIndex, jobDetailIndex) => {
    setErrors([]);
    setMultiLineErrors([]);
    const { name, value } = e.target;
    const formData = { ...form };

    formData.jobDetails[supplierIndex].jobDetail[jobDetailIndex][name] = value;

    setForm(calculateSubtotal(formData));
  };

  const handleFormInputChange = (e) => {
    setErrors([]);
    setMultiLineErrors([]);
    let val = e.target;
    if (val.name === "jobStatus")
      setForm({ ...form, [val.name]: val.value.id });
    else setForm({ ...form, [val.name]: val.value });
  };

  const calculateOutstandingBalance = () => {
    if (form.quote !== "" && form.paidAtm !== "") {
      return parseFloat(form.quote) - parseFloat(form.paidAtm);
    }

    return 0;
  };

  const calculateSubtotal = (formData) => {
    let subtotal = 0;
    formData.jobDetails.map((i) => {
      subtotal += subtotalAtm(i.jobDetail);
    });
    return calculateTax(subtotal, formData);
  };

  const subtotalAtm = (jobDetail) => {
    let subtotal = 0;

    jobDetail.map((item) => {
      if (item.description && item.description.unitPrice) {
        item.rate = parseFloat(item.description.unitPrice);

        if (
          !isNaN(item.quantity) &&
          !isNaN(item.rate) &&
          item.quantity &&
          item.rate
        ) {
          item.amount = parseFloat(item.quantity * item.rate).toFixed(2);
          subtotal += parseFloat(item.amount);
        } else {
          item.amount = 0;
        }
      }
    });

    return subtotal;
  };

  const calculateTax = (subtotal, form) => {
    if (subtotal) {
      form.subTotal = subtotal.toFixed(2);
      form.total = calculateTotal(subtotal, form.tax);
      form.taxAmount = calculatePercentAmt(
        parseFloat(subtotal),
        parseFloat(form.tax)
      );
    } else {
      form.subTotal = 0;
      form.total = 0;
      form.taxAmount = 0;
    }

    return form;
  };

  const calculatePercentAmt = (subTotal, percent) => {
    return (parseFloat(subTotal) * percent * 0.01).toFixed(2);
  };

  const calculateTotal = (subTotal, percent) => {
    return (
      parseFloat(subTotal) * parseFloat(percent) * 0.01 +
      parseFloat(subTotal)
    ).toFixed(2);
  };

  const createJob = async (formInputs) => {
    try {
      setIsLoading(true);
      const response = await api_service.addJob(authTokens, formInputs);

      if (response.status === 201) {
        setIsLoading(false);
        navigate("/job", {
          state: { message: response.data, success: true },
        });
      }
    } catch (error) {
      setIsLoading(false);
      if (error.response.status === 400) {
        let originalObj = error.response.data;
        let arr = multiErrorsCheckForLevels(originalObj, "jobDetails[");

        setMultiLineErrors(arr);
      }

      setErrors(responseErrorMsg(error.response, logoutUser));
    }
  };

  const updateJob = async (jobId, formData) => {
    try {
      setIsLoading(true);
      const response = await api_service.updateJob(authTokens, jobId, formData);
      if (response.status === 200) {
        setIsLoading(false);
        navigate("/job", {
          state: { message: response.data, success: true },
        });
      }
    } catch (error) {
      setIsLoading(false);
      if (error.response.status === 400) {
        let originalObj = error.response.data;
        let arr = multiErrorsCheckForLevels(originalObj, "jobDetails[");

        setMultiLineErrors(arr);
      }

      setErrors(responseErrorMsg(error.response, logoutUser));
    }
  };

  const onSubmit = (e) => {
    e.preventDefault();
    const formData = { ...form };

    if (jobId) updateJob(jobId, formData);
    else createJob(formData);
  };

  const handleAddSupplierClick = (e) => {
    e.preventDefault();
    const inputList = [...form.jobDetails];
    inputList.push({
      supplier: {},
      jobDetail: [{ description: {}, quantity: "", rate: "", amount: "" }],
    });
    setForm({ ...form, jobDetails: inputList });
  };

  const handleAddJobDetailClick = (e, i) => {
    e.preventDefault();
    const formData = { ...form };
    formData.jobDetails[i].jobDetail.push({
      description: {},
      quantity: "",
      rate: "",
      amount: "",
    });
    setForm(formData);
  };

  const handleAddWorkerClick = (e) => {
    e.preventDefault();
    const installerList = [...form.installers];
    installerList.push({});
    setForm({ ...form, installers: installerList });
  };

  const handleRemoveWorkerClick = (e, i) => {
    e.preventDefault();
    const workerList = [...form.installers];
    workerList.splice(i, 1);
    setForm({ ...form, installers: workerList });
  };

  const handleRemoveJobDetailClick = (e, supplierIndex, jobDetailIndex) => {
    e.preventDefault();
    const formData = { ...form };
    formData.jobDetails[supplierIndex].jobDetail.splice(jobDetailIndex, 1);
    setForm(calculateSubtotal(formData));
  };

  const handleRemoveSupplierClick = (e, i) => {
    e.preventDefault();
    const formData = { ...form };
    formData.jobDetails.splice(i, 1);

    setForm(calculateSubtotal(formData));
  };

  const handleBackClick = () => {
    navigate(-1);
  };

  function getIndexValue(id, arr) {
    if (arr) {
      for (let i = 0; i < arr.length; i++) {
        if (arr[i].id === id) {
          return arr[i];
        }
      }
    }
    return "";
  }

  const handleOnChangeGlassInd = (e) => {
    const { checked } = e.target;
    setForm({ ...form, isGlassInd: checked });
  };

  return (
    <div>
      <form onSubmit={onSubmit} noValidate>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Paper elevation={3} sx={{ p: 2 }}>
              <Paper elevation={4} sx={{ p: 3 }}>
                <Error text={errors?.message} />
                <Grid item xs={12}>
                  <Heading
                    variant={"h5"}
                    title={"Job Information"}
                    sxProp={{ m: 2, p: 2, color: grey[500] }}
                  />
                </Grid>

                <Grid container spacing={2}>
                  <Grid item xs={12} sm={4} md={4}>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DatePicker
                        label="Date"
                        name="jobDate"
                        fullWidth
                        color="success"
                        value={dayjs(form.jobDate)}
                        format="YYYY-MM-DD"
                        onChange={(newVal) =>
                          setForm({ ...form, jobDate: newVal })
                        }
                      />
                    </LocalizationProvider>
                    <Error text={errors?.jobDate} />
                  </Grid>
                </Grid>

                <Grid container spacing={2} sx={{ mt: 2 }}>
                  <Grid item xs={12} sm={6} md={6}>
                    <SelectOptions
                      value={
                        builders[Service.getIndex(form.builder.id, builders)] ||
                        ""
                      }
                      noValue={{}}
                      options={builders}
                      name="builder"
                      variant={"filled"}
                      label="Builder Name"
                      handleFormInputChange={(e) => handleFormInputChange(e)}
                    ></SelectOptions>
                    <Error text={errors?.builder} />
                  </Grid>
                  <Grid item xs={12} sm={6} md={6}>
                    <TextField
                      id="jobAddress"
                      label="Job Address"
                      fullWidth
                      color="success"
                      autoComplete="off"
                      variant="filled"
                      inputProps={{ maxLength: 555 }}
                      name="jobAddress"
                      value={form.jobAddress}
                      onChange={(e) => handleFormInputChange(e)}
                    />
                    <Error text={errors?.jobAddress} />
                  </Grid>
                </Grid>

                <Grid item container spacing={2} sx={{ mt: 2 }}>
                  <Grid item xs={12} sm={3} md={3}>
                    <TextField
                      id="quoteId"
                      label="Charge Amount $"
                      fullWidth
                      color="success"
                      autoComplete="off"
                      variant="filled"
                      inputProps={{ maxLength: 555 }}
                      name="quote"
                      value={form.quote}
                      onChange={(e) => handleFormInputChange(e)}
                    />
                    <Error text={errors?.quote} />
                  </Grid>
                  <Grid item xs={12} sm={3} md={3}>
                    <TextField
                      id="paidAtmId"
                      label="Paid Amount $"
                      fullWidth
                      color="success"
                      autoComplete="off"
                      variant="filled"
                      inputProps={{ maxLength: 555 }}
                      name="paidAtm"
                      value={form.paidAtm}
                      onChange={(e) => handleFormInputChange(e)}
                    />
                    <Error text={errors?.paidAtm} />
                  </Grid>
                  <Grid item xs={12} sm={3} md={3}>
                    <TextField
                      id="outstandingId"
                      label="Outstanding Balance $"
                      fullWidth
                      color="success"
                      autoComplete="off"
                      variant="filled"
                      disabled={true}
                      inputProps={{ maxLength: 555 }}
                      name="outstandingAtm"
                      value={form.outstandingAtm}
                      onChange={(e) => handleFormInputChange(e)}
                    />
                  </Grid>
                  <Grid item xs={12} sm={3} md={3}>
                    <TextField
                      id="installationFeeId"
                      label="Installation Fee $"
                      fullWidth
                      color="success"
                      autoComplete="off"
                      variant="filled"
                      inputProps={{ maxLength: 555 }}
                      name="installationFee"
                      value={form.installationFee}
                      onChange={(e) => handleFormInputChange(e)}
                    />
                    <Error text={errors?.installationFee} />
                  </Grid>
                </Grid>

                <Grid item container spacing={2} sx={{ mt: 2 }}>
                  <Grid item xs={12} sm={3} md={3}>
                    <TextField
                      id="woodExpenseId"
                      label="Wood Expense $"
                      fullWidth
                      color="success"
                      autoComplete="off"
                      variant="filled"
                      inputProps={{ maxLength: 555 }}
                      name="woodExpense"
                      value={form.woodExpense}
                      onChange={(e) => handleFormInputChange(e)}
                    />
                    <Error text={errors?.woodExpense} />
                  </Grid>
                  <Grid item xs={12} sm={3} md={3}>
                    <TextField
                      id="glassExpenseId"
                      label="Glass Expense $"
                      fullWidth
                      color="success"
                      autoComplete="off"
                      variant="filled"
                      inputProps={{ maxLength: 555 }}
                      name="glassExpense"
                      value={form.glassExpense}
                      onChange={(e) => handleFormInputChange(e)}
                    />
                    <Error text={errors?.glassExpense} />
                  </Grid>
                  <Grid item xs={12} sm={3} md={3}>
                    <TextField
                      id="spindleExpenseId"
                      label="Spindle Expense $"
                      fullWidth
                      color="success"
                      autoComplete="off"
                      variant="filled"
                      inputProps={{ maxLength: 555 }}
                      name="spindleExpense"
                      value={form.spindleExpense}
                      onChange={(e) => handleFormInputChange(e)}
                    />
                    <Error text={errors?.spindleExpense} />
                  </Grid>
                  <Grid item xs={12} sm={3} md={3}>
                    <TextField
                      id="miscExpenseId"
                      label="Miscellaneous  Expense $"
                      fullWidth
                      color="success"
                      autoComplete="off"
                      variant="filled"
                      inputProps={{ maxLength: 555 }}
                      name="miscExpense"
                      value={form.miscExpense}
                      onChange={(e) => handleFormInputChange(e)}
                    />
                    <Error text={errors?.miscExpense} />
                  </Grid>
            
                  <Grid item xs={12} md={12}>
                    <div className="flex items-center">
                      <Heading
                        variant={"h6"}
                        title={"Job Type"}
                        sxProp={{ m: 2, p: 2, color: grey[500] }}
                      />
                      <div>Glass</div>
                      <Checkbox
                        color="success"
                        size="small"
                        onChange={(e) => handleOnChangeGlassInd(e)}
                        value={form.isGlassInd}
                        inputProps={{
                          "aria-label": "isGlassInd",
                        }}
                      />
                    </div>                    
                  </Grid>
                </Grid>

                {form.installers.map((x, i) => {
                  return (
                    <Grid container spacing={2} key={i} sx={{ mt: 2 }}>
                      <Grid item xs={6}>
                        <SelectOptions
                          value={
                            installers[
                              Service.getIndex(
                                form.installers[i].id,
                                installers
                              )
                            ] || ""
                          }
                          noValue={{}}
                          variant={"filled"}
                          options={installers}
                          name="installer"
                          label="Installer"
                          handleFormInputChange={(e) =>
                            handleMultiWorkerChange(e, i)
                          }
                        ></SelectOptions>
                      </Grid>

                      {i > 0 && (
                        <Grid item xs={4}>
                          <Tooltip title="Delete">
                            <IconButton
                              sx={{ mt: 4 }}
                              aria-label="delete"
                              onClick={(e) => handleRemoveWorkerClick(e, i)}
                            >
                              <CloseOutlinedIcon sx={{ color: red[500] }} />
                            </IconButton>
                          </Tooltip>
                        </Grid>
                      )}
                    </Grid>
                  );
                })}
                <Error text={errors?.installers} />
                <Grid item xs={12} sx={{ pt: 4, pb: 4 }}>
                  <AppButton
                    icon={<AddBox />}
                    btnValue={"Add Installer"}
                    variant={"contained"}
                    type="button"
                    handleClicked={handleAddWorkerClick}
                  ></AppButton>
                </Grid>
              </Paper>

              <Paper elevation={3} sx={{ mt: 2, mb: 4, p: 3 }}>
                <Grid container spacing={2}>
                  <Grid item xs={12} sx={{ textAlign: "right", mt: 2 }}>
                    <AppButton
                      icon={<AddBox />}
                      btnValue={"Add Supplier"}
                      type="button"
                      variant={"contained"}
                      handleClicked={handleAddSupplierClick}
                    ></AppButton>
                  </Grid>
                </Grid>
                {form.jobDetails.length > 0 && (
                  <>
                    <Grid item xs={12}>
                      <div>
                        <Heading
                          variant={"h5"}
                          title={"Details"}
                          sxProp={{ m: 2, p: 2, color: grey[500] }}
                        />
                      </div>
                    </Grid>
                    {form.jobDetails.map((x, i) => {
                      return (
                        <div key={i}>
                          <Grid
                            container
                            spacing={2}
                            sx={{ border: 1, color: grey[800] }}
                          >
                            <Grid item xs={6}>
                              <SelectOptions
                                value={
                                  suppliers[
                                    Service.getIndex(
                                      form.jobDetails[i].supplier.id,
                                      suppliers
                                    )
                                  ] || ""
                                }
                                noValue={{}}
                                variant={"filled"}
                                options={suppliers}
                                name="supplier"
                                label="Supplier"
                                handleFormInputChange={(e) =>
                                  handleMultiSupplierChange(e, i)
                                }
                              ></SelectOptions>
                            </Grid>

                            <Grid item xs={6} sx={{ textAlign: "right" }}>
                              <Tooltip title="Delete">
                                <IconButton
                                  sx={{ mt: 4 }}
                                  aria-label="delete"
                                  onClick={(e) =>
                                    handleRemoveSupplierClick(e, i)
                                  }
                                >
                                  <CloseOutlinedIcon sx={{ color: red[500] }} />
                                </IconButton>
                              </Tooltip>
                            </Grid>

                            {x.jobDetail.map((j, k) => (
                              <Grid container spacing={2} key={k}>
                                <Grid
                                  item
                                  xs={12}
                                  md={4}
                                  lg={3}
                                  sx={{ ml: 2, mt: 4 }}
                                >
                                  <SelectOptions
                                    value={
                                      getIndexValue(
                                        form.jobDetails[i].jobDetail[k]
                                          .description.id,
                                        supplierItems[x.supplier.id]
                                      ) || ""
                                    }
                                    variant={"filled"}
                                    noValue={{}}
                                    options={supplierItems[x.supplier.id]}
                                    name="description"
                                    label="Description"
                                    handleFormInputChange={(e) =>
                                      handleMultiJobDetailChange(e, i, k)
                                    }
                                  ></SelectOptions>
                                  <Error
                                    text={multiLineErrors[i]?.[k]?.description}
                                  />
                                </Grid>
                                <Grid item xs={6} md={3} lg={2} sx={{ mt: 4 }}>
                                  <TextField
                                    id="quantity"
                                    label="Quantity"
                                    fullWidth
                                    color="success"
                                    variant="filled"
                                    autoComplete="off"
                                    name="quantity"
                                    inputProps={{ maxLength: 10 }}
                                    value={j.quantity}
                                    onChange={(e) =>
                                      handleMultiJobDetailChange(e, i, k)
                                    }
                                  />

                                  <Error
                                    text={multiLineErrors[i]?.[k]?.quantity}
                                  />
                                </Grid>
                                <Grid item xs={6} md={2} lg={2} sx={{ mt: 4 }}>
                                  <TextField
                                    id="rate"
                                    label="Rate"
                                    color="success"
                                    variant="filled"
                                    fullWidth
                                    autoComplete="off"
                                    name="rate"
                                    disabled={true}
                                    value={j.rate}
                                  />
                                </Grid>
                                <Grid item xs={9} md={2} lg={2} sx={{ mt: 4 }}>
                                  <TextField
                                    id="amount"
                                    label="Amount"
                                    color="success"
                                    fullWidth
                                    variant="filled"
                                    name="amount"
                                    value={j.amount}
                                    disabled={true}
                                  />
                                </Grid>
                                {k > 0 && (
                                  <Grid item xs={3} md={1} lg={2}>
                                    <Tooltip title="Delete">
                                      <IconButton
                                        sx={{ mt: 4 }}
                                        aria-label="delete"
                                        onClick={(e) =>
                                          handleRemoveJobDetailClick(e, i, k)
                                        }
                                      >
                                        <CloseOutlinedIcon
                                          sx={{ color: red[500] }}
                                        />
                                      </IconButton>
                                    </Tooltip>
                                  </Grid>
                                )}
                              </Grid>
                            ))}

                            <Grid item xs={12} sx={{ mt: 2, mb: 3 }}>
                              <AppButton
                                icon={<AddBox />}
                                btnValue={"Add Details"}
                                type="button"
                                variant={"contained"}
                                handleClicked={(e) =>
                                  handleAddJobDetailClick(e, i)
                                }
                              ></AppButton>
                            </Grid>
                          </Grid>
                        </div>
                      );
                    })}
                    <Error text={errors?.jobDetails} />

                    <Grid item container spacing={2}>
                      <Grid item xs={12} md={2} lg={3}>
                        <TextField
                          id="subTotal"
                          label="Subtotal"
                          variant="filled"
                          name="subTotal"
                          fullWidth
                          color="success"
                          value={form.subTotal}
                          disabled={true}
                          onChange={(e) => handleFormInputChange(e)}
                        />
                      </Grid>

                      <Grid item xs={12} md={2} lg={3}>
                        <TextField
                          id="tax"
                          label="Tax (%)"
                          autoComplete="off"
                          variant="filled"
                          color="success"
                          fullWidth
                          name="tax"
                          disabled={true}
                          value={form.tax}
                          onChange={(e) => handleFormInputChange(e)}
                        />
                      </Grid>

                      <Grid item xs={12} md={2} lg={3}>
                        <TextField
                          id="total"
                          label="Total"
                          variant="filled"
                          fullWidth
                          color="success"
                          name="total"
                          value={form.total}
                          disabled={true}
                          onChange={(e) => handleFormInputChange(e)}
                        />
                      </Grid>
                    </Grid>
                  </>
                )}

                <Grid item container spacing={2}>
                  <Grid item xs={12} sx={{ mt: 2 }}>
                    <TextField
                      id="note"
                      label="Note"
                      fullWidth
                      inputProps={{ maxLength: 2000 }}
                      autoComplete="off"
                      variant="filled"
                      name="note"
                      color="success"
                      value={form.note}
                      onChange={(e) => handleFormInputChange(e)}
                    />
                  </Grid>

                  <Grid item xs={12} sx={{ mb: 2 }}>
                    <SelectOptions
                      value={
                        Service.jobStatus[
                          Service.getIndex(form.jobStatus, Service.jobStatus)
                        ] || ""
                      }
                      noValue={""}
                      handleFormInputChange={handleFormInputChange}
                      options={Service.jobStatus}
                      name="jobStatus"
                      variant={"filled"}
                      isDisabled={false}
                      label="Select Job Status"
                    ></SelectOptions>

                    <Error text={errors?.jobStatus} />
                  </Grid>
                </Grid>
              </Paper>
              <Grid container spacing={2} sx={{ p: 3 }}>
                <Grid item xs={6}>
                  <AppButton
                    icon={<ArrowBackIcon />}
                    btnValue="Back"
                    variant={"contained"}
                    type="button"
                    handleClicked={handleBackClick}
                  ></AppButton>
                </Grid>
                <Grid item xs={6} sx={{ textAlign: "right" }}>
                  <AppButton
                    icon={<SaveAltIcon />}
                    variant={"contained"}
                    btnValue={jobId ? "Update" : "Create"}
                    type="submit"
                  ></AppButton>
                </Grid>
              </Grid>
            </Paper>
          </Grid>
        </Grid>
      </form>
    </div>
  );
};

export default JobForm;
