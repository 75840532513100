import React from "react";
import { Grid, Typography } from "@mui/material";
import { grey } from "@mui/material/colors";

const Footer = () => {
  const year = new Date().getFullYear();

  return (
    <Grid container spacing={1}>
      <Grid container item sx={{ width: 180, position: "fixed", bottom: 0 }}>
        <Grid item xs={12}>
          <Typography variant="caption" sx={{ color: grey[500], m:1}}>
            www.arkrailings.com &copy; {year}
          </Typography>
        </Grid>        
      </Grid>
    </Grid>
  );
};

export default Footer;
