import { SaveAltOutlined } from "@mui/icons-material";
import { Grid, TextField } from "@mui/material";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import AppButton from "common/components/Button";
import Error from "common/components/Error";
import api_service from "common/utils/Api";
import { responseErrorMsg } from "common/utils/ResponseError";
import AppContext from "context/AppContext";
import AuthContext from "context/AuthContext";
import dayjs from "dayjs";
import React, { useContext, useEffect, useState } from "react";

const CommentForm = ({ setOpen, scheduleId }) => {
  const [errors, setErrors] = useState({});
  const { authTokens, logoutUser } = useContext(AuthContext);
  const { value, setValue } = useContext(AppContext);
  const [form, setForm] = useState({
    comment: "",
    date: new Date(),
  });

  useEffect(() => {
    const controller = new AbortController();
    const signal = controller.signal;
    if (scheduleId) getScheduleInfo(scheduleId, signal);

    return () => {
      controller.abort();
    };
  }, []);

  const getScheduleInfo = async (scheduleId, signal) => {
    try {
      const res = await api_service.getScheduleInfo(
        authTokens,
        scheduleId,
        signal
      );
      if (res.status === 200) {
        setForm(res?.data);
      }
    } catch (error) {
      setErrors(responseErrorMsg(error.response, logoutUser));
    }
  };

  const handleFormInputChange = (e) => {
    setErrors({});
    setForm({ ...form, [e.target.name]: e.target.value });
  };

  const onSubmit = (e) => {
    e.preventDefault();
    if(scheduleId)
      updateComment();
    else 
      saveComment();

  };

  const updateComment = async () => {
    try {
      const response = await api_service.updateComment(authTokens, form);
      if (response.status === 200) {
        setValue(!value);
        setForm({ ...form, comment: "" });
        setOpen(false);
      }
    } catch (error) {
      setErrors(responseErrorMsg(error.response, logoutUser));
    }
  };

  const saveComment = async () => {
    try {
      const response = await api_service.saveComment(authTokens, form);
      if (response.status === 201) {
        setValue(!value);
        setForm({ ...form, comment: "" });
        setOpen(false);
      }
    } catch (error) {
      setErrors(responseErrorMsg(error.response, logoutUser));
    }
  };

  return (
    <div>
      <form noValidate onSubmit={onSubmit}>
        <Grid container spacing={2}>
          <Grid item xs={12} sx={{ textAlign: "center" }}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                label="Date"
                name="date"
                fullWidth
                color="success"
                value={dayjs(form.date)}
                format="YYYY-MM-DD"
                onChange={(newVal) => setForm({ ...form, date: newVal })}
              />
            </LocalizationProvider>
            <Error text={errors?.date} />
          </Grid>
          <Grid item xs={12}>
            <TextField
              id="commentId"
              label="Job Description"
              fullWidth
              multiline
              value={form.comment}
              name="comment"
              color="success"
              autoComplete="off"
              variant="filled"
              onChange={(e) => handleFormInputChange(e)}
            />
            <Error text={errors?.comment} />
          </Grid>
          <Grid item xs={12} sx={{ mt: 0 }}>
            <AppButton
              type={"submit"}
              variant={"contained"}
              icon={<SaveAltOutlined />}
              isFullwidth={true}
              btnValue={scheduleId ? "Update" : "Save"}
            />
          </Grid>
        </Grid>
      </form>
    </div>
  );
};

export default CommentForm;
