import { Grid, Typography } from "@mui/material";
import { grey, red } from "@mui/material/colors";
import React from "react";

const ModalBody = (props) => {
  return (
    <Grid container spacing={2}>
      <Grid item xs={12} sx={{ m: 4, p: 2 }}>
        <Typography variant="h6" sx={{ color: grey[800] }}>
          {props.children}
        </Typography>
      </Grid>
    </Grid>
  );
};

export default ModalBody;
