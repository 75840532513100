import React, { useState, useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Paper, Grid } from "@mui/material";
import SaveAltIcon from "@mui/icons-material/SaveAlt";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import UpdateIcon from "@mui/icons-material/Update";
import Service from "common/utils/Common";
import AuthContext from "context/AuthContext";
import AppButton from "common/components/Button";
import ItemInfo from "supplier/components/ItemInfo";
import BasicInfo from "common/components/BasicInfo";
import api_service from "common/utils/Api";
import { multiErrorsCheck } from "common/utils/MultiLineError";
import { responseErrorMsg } from "common/utils/ResponseError";

export const PersonOrgForm = ({ id, formName }) => {
  const navigate = useNavigate();
  let { authTokens, logoutUser } = useContext(AuthContext);
  const [addressSuggestion, setAddressSuggestion] = useState([]);

  const [formData, setFormData] = useState({
    id: "",
    name: "",
    email: "",
    phone: "",
    address: "",
    postalCode: "",
    province: "",
    country: "",
    city: "",
    items: [{ name: "", unitPrice: "" }],
  });

  const [errors, setErrors] = useState({});
  const [multiLineErrors, setMultiLineErrors] = useState([]);

  useEffect(() => {
    const controller = new AbortController();
    const signal = controller.signal;
    if (id) getPersonOrgById(id, signal);

    return () => {
      controller.abort();
    };
  }, []);

  const getPersonOrgById = async (id, signal) => {
    try {
      const response =
        formName === "supplier"
          ? await api_service.getSupplierById(authTokens, id, signal)
          : formName === "installer"
          ? await api_service.getInstallerById(authTokens, id, signal)
          : await api_service.getBuilderById(authTokens, id, signal);

      if (response.status === 200) {
        response.data.province =
          Service.province[
            Service.getIndex(response.data.province, Service.province)
          ];
        setFormData(response.data);
      }
    } catch (error) {
      if (error?.name !== "CanceledError") {
        setErrors(responseErrorMsg(error.response, logoutUser));
      }
    }
  };

  const handleFormInputChange = (e) => {
    setErrors([]);
    setMultiLineErrors([]);
    if (e.target.name === "address") {
      if (e.target.value) {
        retrieveAddress(e.target.value);
      } else {
        setFormData({
          ...formData,
          city: "",
          postalCode: "",
          country: "",
          province: "",
        });
        setAddressSuggestion([]);
      }
    } else {
      setFormData({ ...formData, [e.target.name]: e.target.value });
    }
  };

  const retrieveAddress = async (address) => {
    try {
      const response = await api_service.retrieveAddress(address);
      if (response.status === 200) {
        setAddressSuggestion(response.data.suggestions);
      }
    } catch (error) {
      setErrors(responseErrorMsg(error.response, logoutUser));
    }
  };

  const handleMultiInputChange = (e, i) => {
    setErrors([]);
    setMultiLineErrors([]);
    const { name, value } = e.target;
    const inputList = [...formData.items];
    inputList[i][name] = value;
    setFormData({ ...formData, items: inputList });
  };

  const handleAddItemClick = (e) => {
    e.preventDefault();
    const inputList = [...formData.items];
    inputList.push({ name: "", unit_price: "" });
    setFormData({ ...formData, items: inputList });
  };

  const handleRemoveClick = (e, i) => {
    e.preventDefault();
    const multiInputs = [...formData.items];
    multiInputs.splice(i, 1);
    setFormData({ ...formData, items: multiInputs });
  };

  const onSubmit = (e) => {
    e.preventDefault();

    if (formName === "installer" || formName === "builder")
      delete formData.items;

    const form = { ...formData, province: formData.province.id };
    if (id) updatePersonOrg(id, form);
    else createPersonOrg(form);
  };

  const updatePersonOrg = async (id, formData) => {
    try {
      const response =
        formName === "supplier"
          ? await api_service.updateSupplier(authTokens, id, formData)
          : formName === "installer"
          ? await api_service.updateInstaller(authTokens, id, formData)
          : await api_service.updateBuilder(authTokens, id, formData);

      if (response.status === 200) {
        navigate(`/${formName}`, {
          state: { message: response.data, success: true },
        });
      }
    } catch (error) {
      if (error.response.status === 400) {
        if (formName === "supplier") {
          let originalObj = error.response.data;
          let arr = multiErrorsCheck(originalObj, "items");
          setMultiLineErrors(arr);
        }
      }

      setErrors(responseErrorMsg(error.response, logoutUser));
    }
  };

  const createPersonOrg = async (formData) => {
    try {
      const response =
        formName === "supplier"
          ? await api_service.addSupplier(authTokens, formData)
          : formName === "installer"
          ? await api_service.addInstaller(authTokens, formData)
          : await api_service.addBuilder(authTokens, formData);

      if (response.status === 201) {
        navigate(`/${formName}`, {
          state: { message: response.data, success: true },
        });
      }
    } catch (error) {
      if (error.response.status === 400) {
        if (formName === "supplier") {
          let originalObj = error.response.data;
          let arr = multiErrorsCheck(originalObj, "items");
          setMultiLineErrors(arr);
        }
      }

      setErrors(responseErrorMsg(error.response, logoutUser));
    }
  };

  const handleBackClick = () => {
    navigate(-1);
  };

  const handleOnClick = (addressText) => {
    retrieveFullAddress(addressText);
  };

  const retrieveFullAddress = async (address) => {
    try {
      const response = await api_service.retrieveFullAddress(address);
      if (response.status === 200) {
        const fullAddress = response.data.candidates[0];

        setFormData({
          ...formData,
          address: fullAddress.attributes.ShortLabel,
          city: fullAddress.attributes.City,
          province:
            Service.province[
              Service.getIndex(
                fullAddress.attributes.RegionAbbr,
                Service.province
              )
            ],
          country: fullAddress.attributes.CntryName,
          postalCode:
            fullAddress.attributes.Postal +
            " " +
            fullAddress.attributes.PostalExt,
        });
        setAddressSuggestion([]);
      }
    } catch (error) {
      setErrors(responseErrorMsg(error.response, logoutUser));
    }
  };

  return (
    <>
      <form onSubmit={onSubmit} noValidate>
        <input type="hidden" value={formData.id} name="id" />
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Paper elevation={1}>
              <BasicInfo
                formData={formData}
                title={formName + " Information"}
                errors={errors}
                handleFormInputChange={handleFormInputChange}
                addressSuggestion={addressSuggestion}
                handleOnClick={handleOnClick}
              />

              {formName == "supplier" && (
                <>
                  <ItemInfo
                    title="Products/Services Information"
                    handleMultiInputChange={handleMultiInputChange}
                    formData={formData}
                    errors={errors}
                    multiErrors={multiLineErrors}
                    handleRemoveClick={handleRemoveClick}
                    handleAddItemClick={handleAddItemClick}
                  />
                </>
              )}

              <Grid container spacing={2} sx={{ p: 5 }}>
                <Grid item xs={6}>
                  <AppButton
                    icon={<ArrowBackIcon />}
                    btnValue="Back"
                    type="button"
                    variant={"contained"}
                    handleClicked={handleBackClick}
                  ></AppButton>
                </Grid>

                <Grid item xs={6} sx={{ textAlign: "right" }}>
                  <AppButton
                    variant={"contained"}
                    icon={formData.id ? <UpdateIcon /> : <SaveAltIcon />}
                    btnValue={formData.id ? "Update" : "Save"}
                    type="submit"
                  ></AppButton>
                </Grid>
              </Grid>
            </Paper>
          </Grid>
        </Grid>
      </form>
    </>
  );
};
