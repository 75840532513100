import { Divider, Grid } from "@mui/material";
import { grey } from "@mui/material/colors";
import Error from "common/components/Error";
import Heading from "common/components/Heading";
import api_service from "common/utils/Api";
import { responseErrorMsg } from "common/utils/ResponseError";
import AppContext from "context/AppContext";
import AuthContext from "context/AuthContext";
import TableData from "job/components/TableData";
import React, { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";

const BuilderJob = () => {
  const [page, SetPage] = useState(0);
  const [pageSize, SetPageSize] = useState(10);
  const { builderId } = useParams();
  const { authTokens, logoutUser } = useContext(AuthContext);
  const [errors, setErrors] = useState({});
  const [jobs, setJobs] = useState({});
  const { value, setIsLoading } = useContext(AppContext);
  const [searchform, setSearchForm] = useState({
    jobDate: "",
    builder: {},
    jobAddress: "",
  });

  useEffect(() => {
    const controller = new AbortController();
    const signal = controller.signal;

    getBuilderJobs(page, pageSize, signal);

    return () => {
      controller.abort();
    };
  }, []);

  const getBuilderJobs = async (page, size, signal) => {
    try {
      setIsLoading(true);
      const response = await api_service.getBuilderJobs(
        authTokens,
        builderId,
        page,
        size,
        signal
      );
      if (response.status === 200) {
        setJobs(response.data);
        setIsLoading(false);
      }
    } catch (error) {
      setIsLoading(false);
      if (error?.name !== "CanceledError") {
        setErrors(responseErrorMsg(error.response, logoutUser));
      }
    }
  };

  const handleChangeRowsPerPage = (e) => {
    let pgSize = parseInt(e.target.value);
    SetPageSize(pgSize);
    getBuilderJobs(page, pgSize);
  };

  const handleClickClear = () => {
    setSearchForm({
      jobDate: "",
      builder: {},
      jobAddress: "",
    });
    getBuilderJobs(page, pageSize);
  };
  const onSubmitSearch = (e) => {
    e.preventDefault();
    if (searchform.jobAddress === "" && searchform.jobDate === "") {
      return;
    }

    searchByUserInput(searchform, page, pageSize);
  };

  const searchByUserInput = async (searchform, page, pageSize) => {
    try {
      setIsLoading(true);
      const response = await api_service.searchJobByUserInput(
        authTokens,
        searchform,
        page,
        pageSize,
        ""
      );
      if (response.status === 200) {
        setIsLoading(false);
        setJobs(response.data);
      }
    } catch (error) {
      setIsLoading(false);
      setErrors(responseErrorMsg(error.response, logoutUser));
    }
  };

  const handleSearchFormInputChange = (e) => {
    const { value, name } = e.target;
    setSearchForm({ ...searchform, [name]: value });
  };
  const handleChangePageActive = (e, v) => {
    let pg = parseInt(v);
    SetPage(pg);
    getBuilderJobs(pg, pageSize);
  };
  return (
    <div>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Heading
            variant={"h3"}
            title={`Job [${jobs?.content?.length}]`}
            sxProp={{ fontWeight: 800, m: 2, p: 2, color: grey[600] }}
          />
          <Divider />
        </Grid>
        <Error title={errors?.message} />
        <Grid item xs={12}>
          <TableData
            data={jobs}
            handleChangePage={handleChangePageActive}
            page={page}
            pageSize={pageSize}
            handleChangeRowsPerPage={handleChangeRowsPerPage}
            handleSearchFormInputChange={handleSearchFormInputChange}
            onSubmitSearch={onSubmitSearch}
            searchForm={searchform}
            handleClickClear={handleClickClear}
            setSearchForm={setSearchForm}
          />
        </Grid>
      </Grid>
    </div>
  );
};

export default BuilderJob;
