import React, { useContext, useState } from "react";
import Modal from "modals/components/Modal";
import ModalBody from "modals/components/ModalBody";
import ModalHeader from "modals/components/ModalHeader";
import ModalFooter from "modals/components/ModalFooter";
import AuthContext from "context/AuthContext";
import AppContext from "context/AppContext";
import AppButton from "common/components/Button";
import { useNavigate } from "react-router-dom";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import api_service from "common/utils/Api";
import { responseErrorMsg } from "common/utils/ResponseError";
import Error from "./Error";
import common from "common/utils/Common";

const DeleteConfirmModal = (props) => {
  const { value, setValue } = useContext(AppContext);
  const { authTokens, logoutUser } = useContext(AuthContext);
  const navigate = useNavigate();
  const [disabled, setDisabled] = useState(false);
  const [errors, setErrors] = useState(false);

  const onSubmit = (e) => {
    setDisabled(true);
    e.preventDefault();
    switch (props.type) {
      case common.INSTALLER:
        deleteEntity(common.INSTALLER);
        break;
      case common.BUILDER:
        deleteEntity(common.BUILDER);
        break;
      case common.SUPPLIER:
        deleteEntity(common.SUPPLIER);
        break;
      case common.JOB:
        deleteEntity(common.JOB);
        break;
      case common.COMMENT:
        deleteEntity(common.HOME);
        break;
      case common.CONFIRMATION:
        confirmMsg();
        break;
      default:
        break;
    }
  };

  const confirmMsg = async () => {
    try {
      const response = await api_service.updateJobStatus(
        authTokens,
        props.jobIds,
        props.status
      );
      if (response.status === 200) {
       setValue(!value);
       props.close();
      }

    }catch(error) {
      setErrors(responseErrorMsg(error.response, logoutUser));
    }
  }

  const deleteEntity = async (goToPage) => {
    try {
      let response = null;
      if (goToPage === common.SUPPLIER) {
        response = await api_service.deleteSupplier(
          authTokens,
          props.supplierId
        );
      }

      if (goToPage === common.INSTALLER) {
        response = await api_service.deleteInstaller(
          authTokens,
          props.workerId
        );
      }

      if (goToPage === common.JOB) {
        response = await api_service.deleteJob(authTokens, props.jobIds);
      }

      if (goToPage === common.BUILDER) {
        response = await api_service.deleteBuilder(authTokens, props.builderId);
      }

      if (goToPage === common.HOME) {
        response = await api_service.deleteComment(authTokens, props.commentId);
      }

      if (response.status === 200) {
        setValue(!value);
        props.close();
        navigate(`/${goToPage}`, {
          state: { message: response.data, success: true },
        });
      }
    } catch (error) {     
      setErrors(responseErrorMsg(error.response, logoutUser));
    }
  };

  const handleClicked = () => {
    props.close();
  };

  return (
    <>
      <form onSubmit={onSubmit} noValidate>
        <Modal>
          <Error text={errors?.message} />
          <ModalHeader>{props.title}</ModalHeader>
          <ModalBody>
            <p>
              {props.message} <b>{props.placeholder}</b>?
            </p>
          </ModalBody>
          <ModalFooter>
            <AppButton
              icon={<CheckCircleIcon />}
              btnValue={"Confirm"}
              type="submit"
              bgColor={"blue"}
              colorIndex="500"
              variant={"contained"}
              isDisabled={disabled}
            ></AppButton>
            &nbsp;
            <AppButton
              icon={<HighlightOffIcon />}
              btnValue="Cancel"
              variant={"contained"}
              handleClicked={handleClicked}
              type="button"
            ></AppButton>
          </ModalFooter>
        </Modal>
      </form>
    </>
  );
};

export default DeleteConfirmModal;
