import "./App.css";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import RootLayout from "common/components/RootLayout";
import Home from "dashboard/index";
import Login from "login/index";
import { AuthProvider } from "context/AuthContext";
import { AppProvider } from "context/AppContext";
import Supplier from "supplier/index";
import AddSupplier from "supplier/components/AddSupplier";
import EditSupplier from "supplier/components/EditSupplier";
import Installer from "installer/index";
import AddInstaller from "installer/components/AddInstaller";
import EditInstaller from "installer/components/EditInstaller";
import Job from "job/index";
import AddJob from "job/components/AddJob";
import EditJob from "job/components/EditJob";
import Builder from "builder/index";
import AddBuilder from "builder/components/AddBuilder";
import EditBuilder from "builder/components/EditBuilder";
import ResetPassword from "login/components/ResetPassword";
import Account from "account";
import AddUser from "account/components/AddUser";
import EditUser from "account/components/EditUser";
import PrivateRoute from "common/utils/PrivateRoute";
import Settings from "settings/index";
import BuilderJob from "dashboard/components/BuilderJob";
import Landing from "landing/index";
import Payment from 'payment/index'
import PaymentDetails from 'payment/components/PaymentDetails'

function App() {
  return (
    <>
      <Router>
        <AuthProvider>
          <AppProvider>
            <Routes>
              <Route path="/" element={<Landing />}></Route>

              <Route path="/login" element={<Login />}></Route>

              <Route path="/resetPassword" element={<ResetPassword />}></Route>

              <Route element={<PrivateRoute />}>
                <Route path="/" element={<RootLayout />}>
                  <Route path="home" index element={<Home />}></Route>

                  <Route path="account">
                    <Route index element={<Account />}></Route>
                    <Route
                      path="/account/newAccount"
                      element={<AddUser />}
                    ></Route>
                    <Route
                      path="/account/editAccount/:userId"
                      element={<EditUser />}
                    ></Route>
                  </Route>

                  <Route path="supplier">
                    <Route index element={<Supplier />} />
                    <Route
                      path="/supplier/newSupplier"
                      element={<AddSupplier />}
                    />
                    <Route
                      path="/supplier/editSupplier/:supplierId"
                      element={<EditSupplier />}
                    />
                  </Route>

                  <Route path="installer">
                    <Route index element={<Installer />}></Route>
                    <Route
                      path="/installer/newInstaller"
                      element={<AddInstaller />}
                    ></Route>
                    <Route
                      path="/installer/editInstaller/:installerId"
                      element={<EditInstaller />}
                    ></Route>
                  </Route>

                  <Route path="payment">
                    <Route index element={<Payment />}></Route>
                    <Route
                      path="/payment/details/:installerId"
                      element={<PaymentDetails />}
                    ></Route>
                  </Route>

                  <Route path="builder">
                    <Route index element={<Builder />}></Route>
                    <Route
                      path="/builder/newBuilder"
                      element={<AddBuilder />}
                    ></Route>
                    <Route
                      path="/builder/editBuilder/:builderId"
                      element={<EditBuilder />}
                    ></Route>
                  </Route>

                  <Route path="job">
                    <Route index element={<Job />}></Route>
                    <Route path="/job/newJob" element={<AddJob />}></Route>
                    <Route
                      path="/job/editJob/:jobId"
                      element={<EditJob />}
                    ></Route>
                  </Route>

                  <Route path="settings">
                    <Route index element={<Settings />}></Route>
                  </Route>

                  <Route path="builderDetails">
                    <Route
                      path="/builderDetails/:builderId"
                      element={<BuilderJob />}
                    ></Route>
                  </Route>
                </Route>
              </Route>
            </Routes>
          </AppProvider>
        </AuthProvider>
      </Router>
    </>
  );
}

export default App;
