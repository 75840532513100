import React from "react";
import Login from "login/components/Login";
import Header from "header/components/Header";
import LoginFooter from "./components/LoginFooter";

const Index = () => {
  window.scroll(0, 0);

  return (
    <>
      <Header />

      <div className="w-full">
        <div className="max-w-[1240px] h-screen border-gray-300
        text-center m-auto flex flex-col items-center justify-center"
        >
          <div className="w-[50%]">
            <Login />
          </div>
        </div>
      </div>

      <LoginFooter />
    </>
  );
};

export default Index;
