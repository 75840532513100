import Header from "header/components/Header";
import { Grid, TextField, Typography } from "@mui/material";
import { green, grey } from "@mui/material/colors";
import LoginFooter from "./LoginFooter";
import { useNavigate } from "react-router-dom";
import api_service from "common/utils/Api";
import { useContext, useState } from "react";
import Error from "common/components/Error";
import AppContext from "context/AppContext";

const ResetPassword = () => {
  const navigate = useNavigate();

  const [errors, setErrors] = useState({});
  const [successMessage, setSuccessMessage] = useState("");
  const [isDisabled, setIsDisabled] = useState(false);
  const { setIsLoading } = useContext(AppContext);

  const handleClickedCancel = (e) => {
    navigate("/login");
  };

  const onSubmit = (e) => {
    e.preventDefault();
    if (e.target.email.value) resetPassword(e.target.email.value);
  };

  const resetPassword = async (email) => {
    try {
      setIsLoading(true);
      setIsDisabled(true);
      const response = await api_service.resetPassword(email);
      if (response.status === 200) {
        setIsLoading(false);
        setSuccessMessage(response.data);
      }
    } catch (error) {
      setIsDisabled(false);
      setIsLoading(false);

      if (typeof error.response.data === "string") {
        errors["message"] = error.response.data;
      }

      setErrors(errors);
    }
  };
  return (
    <>
      <Header />

      <form noValidate onSubmit={onSubmit}>
        <div className="w-full">
          <div
            className="max-w-[1240px] h-screen
        text-center m-auto flex flex-col items-center justify-center"
          >
            <Grid container>
              <Grid item xs={12}>
                <Typography variant="body1" sx={{ color: grey[600], m: 2 }}>
                  Please enter your email to reset your password.
                </Typography>
              </Grid>

              <Grid container item sx={{ textAlign: "center" }} spacing={2}>
                <Grid item xs={12}>
                  <TextField
                    variant="filled"
                    name="email"
                    size="small"
                    color="success"
                    autoComplete="off"
                    id="email"
                    label="Email"
                    onChange={() => setErrors({})}
                  ></TextField>
                </Grid>
                <Grid item xs={12}>
                  <button
                    disabled={isDisabled}
                    type="submit"
                    className="bg-amber-300 py-2 px-6
             text-gray-900 border-amber-300 rounded-3xl shadow-xl"
                  >
                    Reset
                  </button>
                  &nbsp;&nbsp;
                  <button
                    onClick={handleClickedCancel}
                    className="bg-amber-300 py-2 px-6 rounded-3xl
             text-gray-900 border-amber-300 shadow-xl"
                  >
                    Cancel
                  </button>
                </Grid>
              </Grid>

              <Grid item xs={12}>
                <Error text={errors?.message} />
                <Typography variant="body1" sx={{ color: green[600] }}>
                  {successMessage}
                </Typography>
              </Grid>
            </Grid>
          </div>
        </div>
      </form>

      <LoginFooter />
    </>
  );
};

export default ResetPassword;
