import { grey } from '@mui/material/colors';
import Heading from 'common/components/Heading';
import React from 'react'
import UserForm from './UserForm';

const AddUser = () => {
  return (
    <>
      <div>
        <div>
          <Heading
            variant={"h3"}
            title={"New Account"}
            sxProp={{ fontWeight: 800, m: 2, p: 2, color: grey[600] }}
          />
        </div>
         <UserForm title={"Add User"} id="" />
        
      </div>
    </>
  );
}

export default AddUser
