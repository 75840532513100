import { Grid } from "@mui/material";
import React from "react";

const ModalFooter = (props) => {
  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12} sx={{ m: 4, p: 2 }}>
          {props.children}
        </Grid>
      </Grid>
    </>
  );
};

export default ModalFooter;
