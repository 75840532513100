import React from "react";
import { Grid, Typography } from "@mui/material";

const LoginFooter = () => {
  const year = new Date().getFullYear();
  return (
    <div className="index-footer">
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography variant="caption" sx={{m:2}}>
            &copy; {year} ARK Railings
          </Typography>
        </Grid>
      </Grid>      
    </div>
  );
};

export default LoginFooter;