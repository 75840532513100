import React from "react";
import stair_480w from "assets/images/stair3d_480w.webp";
import stair3d from "assets/images/stair3d.webp";
import stair_800w from "assets/images/stair3d_800w.webp";
import { Link, animateScroll as scroll } from "react-scroll";
import { Place } from "@mui/icons-material";

const LandingHome = () => {
  return (
    <div name="home" className="w-full">
      <div className="max-w-[1240px] m-auto ">
        <div className="flex flex-col sm:flex-row justify-center items-center min-h-screen">
          <div className=" text-center py-2">
            <h3 className="text-5xl sm:text-7xl font-bold text-gray-700 w-full text-center">
              ARK Railings
            </h3>
            <p className="p-4 text-sm sm:text-md text-slate-600">
              Our mission is to deliver tailor-made railing solutions that
              enhance both the aesthetic allure and structural integrity of any
              space.
            </p>
            <p className="text-sm sm:text-md text-gray-700">
              <Place className="animate-bounce" />
              &nbsp;Edmonton, AB
            </p>
            <p className="text-center py-8 w-full">
              <Link to="contact" offset={-85} smooth={true} duration={500}>
                <button
                  className="bg-amber-300 w-[350px] rounded-3xl  text-gray-700 hover:text-gray-700 text-xl
            font-bold px-6 border border-amber-300 py-3 hover:shadow-lg hover:bg-amber-400 "
                >
                  Contact Us
                </button>
              </Link>
            </p>
          </div>

          <div className="hidden sm:block">
            <img
              className="p-4"
              src={stair_480w}
              loading="lazy"
              srcSet={`${stair_480w} 480w, ${stair_800w} 800w, ${stair3d} 1240w`}
              sizes="(max-width: 480px) 480px,800px"
              alt="about"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default LandingHome;
