import { AddBox, Logout } from "@mui/icons-material";
import { Grid, Typography } from "@mui/material";
import { grey } from "@mui/material/colors";
import AppButton from "common/components/Button";
import Error from "common/components/Error";
import GenericTable from "common/components/GenericTable";
import Heading from "common/components/Heading";
import api_service from "common/utils/Api";
import { responseErrorMsg } from "common/utils/ResponseError";
import AppContext from "context/AppContext";
import AuthContext from "context/AuthContext";
import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

const Account = () => {
  const navigate = useNavigate();

  const { authTokens, logoutUser } = useContext(AuthContext);
  const { setIsLoading } = useContext(AppContext);
  const [users, setUsers] = useState([]);
  const [errors, setErrors] = useState({});

  useEffect(() => {
    const controller = new AbortController();
    const signal = controller.signal;
    getAllUsers(signal);

    return () => {
      controller.abort();
    };
  }, []);

  const getAllUsers = async (signal) => {
    try {
      setIsLoading(true);
      const response = await api_service.getAllUsers(authTokens, signal);
      if (response.status === 200) {
        setIsLoading(false);
        setUsers(response.data);
      }
    } catch (error) {
      setIsLoading(false);
      if (error?.name !== "CanceledError") {
        setErrors(responseErrorMsg(error.response, logoutUser));
      }
    }
  };

  const handleClickNew = () => {
    navigate("/account/newAccount");
  };

  return (
    <>
      <Grid
        container
        sx={{
          border: 1,
          m: 0,
          color: grey[900],
          borderStyle: "none none solid none",
        }}
      >
        <Grid item xs={6}>
          <Heading
            variant={"h3"}
            title={`User Account [${users.length}]`}
            sxProp={{ fontWeight: 800, m: 2, p: 2, color: grey[600] }}
          />
        </Grid>
        <Grid xs={6} sx={{ textAlign: "right", p: 2 }}>
          <div onClick={logoutUser}>
            <Typography
              variant="caption"
              sx={{
                pl: 2,
                color: grey[600],
                fontWeight: 600,
                cursor: "pointer",
              }}
            >
              Logout&nbsp;
              <Logout sx={{ fontSize: "8pt" }} />
            </Typography>
          </div>
        </Grid>
      </Grid>

      <Error text={errors.message} />

      <Grid container spacing={2} sx={{ p: 3 }}>
        <Grid item xs={12}>
          <AppButton
            icon={<AddBox />}
            btnValue={"Add New"}
            type="button"
            variant={"contained"}
            handleClicked={handleClickNew}
          ></AppButton>
        </Grid>
      </Grid>

      <Grid container>
        <Grid item xs={12}>
          <GenericTable
            tableHeaders={[
              "S.No.",
              "First Name",
              "Last Name",
              "Email",
              "Enabled",
              "Created",
              "Roles",
            ]}
            tableData={users}
            dataAttribute={[
              "firstName",
              "lastName",
              "email",
              "isEnabled",
              "createdDate",
              "roles",
            ]}
            path="/account/editAccount"
            showSN={true}
            toolTipTxt="Edit Account"
          />
        </Grid>
      </Grid>
    </>
  );
};

export default Account;
