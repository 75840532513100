import React from "react";
import Navbar from "landing/components/Navbar";
import ContactUs from "./components/ContactUs";
import About from "./components/About";
import Gallery from "./components/GallerySection";
import LandingHome from "./components/LandingHome";

const Landing = () => {
  return (
    <>
      <Navbar />

      <LandingHome />

      <Gallery />

      <About />

      <ContactUs />
    </>
  );
};

export default Landing;
