import { grey } from "@mui/material/colors";
import Heading from "common/components/Heading";
import React from "react";
import UserForm from "./UserForm";
import { useParams } from "react-router-dom";

const EditUser = () => {
 const { userId } = useParams();
  return (
    <>
      <div>
        <div>
          <Heading
            variant={"h3"}
            title={"Edit Account"}
            sxProp={{ fontWeight: 800, m: 2, p: 2, color: grey[600] }}
          />
        </div>
        <UserForm title={"Edit User"} id={userId} />
      </div>
    </>
  );
};

export default EditUser;
