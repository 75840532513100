import React, { useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  IconButton,
  Tooltip,
  Typography,
  TablePagination,
  Grid,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import { tableCellClasses } from "@mui/material/TableCell";
import { amber, blue, green, grey, orange, red, yellow } from "@mui/material/colors";
import Data from "./Data";
import { DoneAll, HourglassTop, Padding, Warehouse } from "@mui/icons-material";
import AppButton from "./Button";

const GenericTable = ({
  tableHeaders,
  tableData,
  dataAttribute,
  deleteConfirmModal,
  path,
  toolTipTxt,
  showSN,
  handleJobStatusUpdate,
  page,
  pageSize,
  isGlassTable,
  totalElements,
  handleChangePage,
  handleChangeRowsPerPage,
}) => {
  const [glassStatus, setGlassStatus] = useState("");
  const [rowIndex, setRowIndex] = useState(-1);
  const [inProgrressColor, setInProgressColor] = useState("#000");

  return (
    <div>
      {tableData && tableData.length > 0 ? (
        <>
          <TableContainer component={Paper}>
            <Table aria-label="table">
              <TableHead>
                <TableRow>
                  {tableHeaders.map((headerName, index) => (
                    <StyledTableCell
                      sx={{
                        textAlign:
                          headerName === "Tax" ||
                          headerName === "Earned" ||
                          headerName === "Status"
                            ? "center"
                            : headerName === "Outstanding Payment"
                            ? "right"
                            : "left",
                      }}
                      key={index}
                    >
                      {headerName}
                    </StyledTableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {tableData.map((row, index) => (
                  <StyledTableRow
                    key={row.id ? row.id : index}
                    style={{
                      background: row.isGlassInd
                        ? row.jobStatus === "I"
                          ? "#FEF9F2"
                          : row.jobStatus === "W"
                          ? orange[50]
                          : green[50]
                        : "",
                      color: row.isGlassInd ? red[900] : "",
                    }}
                  >
                    {showSN && <StyledTableCell>{index + 1}</StyledTableCell>}

                    <Data
                      data={dataAttribute}
                      path={path}
                      row={row}
                      toolTipTxt={toolTipTxt}
                      rowIndex={index}
                    />

                    {row.isGlassInd && (
                      <StyledTableCell>
                        <div className="flex justify-center">
                          <AppButton
                            type={"button"}
                            icon={<HourglassTop />}
                            size={"small"}
                            sx={{
                              mr: 1,
                              p: 1,
                              backgroundColor: "#FEF9F2",
                              color: grey[800],
                              fontSize: 11,
                            }}
                            handleClicked={() =>
                              handleJobStatusUpdate(row, "I")
                            }
                            variant={"contained"}
                          />
                          <AppButton
                            type={"button"}
                            icon={<Warehouse />}
                            size={"small"}
                            sx={{
                              mr: 1,
                              p: 1,
                              backgroundColor: orange[50],
                              color: grey[800],
                              fontSize: 11,
                            }}
                            handleClicked={() =>
                              handleJobStatusUpdate(row, "W")
                            }
                            variant={"contained"}
                          />
                          <AppButton
                            type={"button"}
                            icon={<DoneAll />}
                            size={"small"}
                            sx={{
                              mr: 1,
                              p: 1,
                              backgroundColor: green[50],
                              color: grey[800],
                              fontSize: 11,
                            }}
                            handleClicked={() =>
                              handleJobStatusUpdate(row, "C")
                            }
                            variant={"contained"}
                          />
                        </div>
                      </StyledTableCell>
                    )}

                    {deleteConfirmModal && (
                      <StyledTableCell>
                        <Tooltip title="Delete">
                          <IconButton
                            aria-label="delete"
                            onClick={() => deleteConfirmModal(row)}
                          >
                            <DeleteForeverIcon
                              className="error"
                              sx={{ color: red[400] }}
                            />
                          </IconButton>
                        </Tooltip>
                      </StyledTableCell>
                    )}
                  </StyledTableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          {isGlassTable && (
            <Grid container spacing={2}>
              <Grid item xs={12} sx={{ textAlign: "right" }}>
                <TablePagination
                  component="div"
                  sx={{ color: grey[600] }}
                  count={totalElements}
                  page={page}
                  onPageChange={(event, value) =>
                    handleChangePage(event, value)
                  }
                  rowsPerPage={pageSize}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                />
              </Grid>
            </Grid>
          )}
        </>
      ) : (
        <Typography variant="subtitle1" sx={{ m: 2, color: grey[500] }}>
          No Data Available
        </Typography>
      )}
    </div>
  );
};

export default GenericTable;

export const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.primary.main,
    color: grey[800],        
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: grey[100],
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));
