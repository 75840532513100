import React, { useContext, useEffect} from "react";
import AuthContext from "context/AuthContext";
import {
  Grid,
  TextField,
  Typography,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import Paper from "@mui/material/Paper";
import { grey } from "@mui/material/colors";
import Error from "common/components/Error";
import { Link } from "react-router-dom";
import AppButton from "common/components/Button";
import { LoginOutlined } from "@mui/icons-material";


const Login = () => {
  const { loginUser, errors, setErrors } = useContext(AuthContext);

  useEffect(() => {
    setErrors({});
  }, []);

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Error text={errors?.message} />
        </Grid>
      </Grid>
      <form onSubmit={loginUser}>
        <Grid container spacing={1}>
          <div className="border  w-full p-4 rounded-xl">
            <Grid item xs={12}>
              <TextField
                variant="filled"
                name="email"
                size="small"
                fullWidth
                color="success"
                autoComplete="off"
                id="email"
                label="Email"
                onChange={() => setErrors([])}
              ></TextField>
              <Error text={errors?.email} />
            </Grid>
            <Grid item xs={12}>
              <TextField
                variant="filled"
                color="success"
                size="small"
                fullWidth
                id="password"
                type="password"
                autoComplete="off"
                label="Password"
                onChange={() => setErrors([])}
              ></TextField>
              <Error text={errors?.password} />
            </Grid>

            <Grid item xs={12} >
              <button
                type="submit"
                className="bg-amber-300 py-2 my-2 px-6 rounded-3xl
             text-gray-900 border-amber-300 shadow-xl w-full"
              >
                Login
              </button>
            </Grid>
            <Grid item xs={12} sx={{ mt: 2 }}>
              <Typography variant="caption" sx={{ color: grey[600]}}>
                <Link to="/resetPassword"> Forgot Password?</Link>
              </Typography>
            </Grid>
          </div>
        </Grid>
      </form>
    </>
  );
};

export default Login;
