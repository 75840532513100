import { Backdrop, CircularProgress } from "@mui/material";
import React, { createContext, useState} from "react";

const AppContext = createContext();

export default AppContext;

export const AppProvider = ({ children }) => {
  const [value, setValue] = useState(false);
  const [isLoading, setIsLoading] = useState(false);


  let contextData = {
    value: value,
    setValue: setValue,
    isLoading: isLoading,
    setIsLoading: setIsLoading,
  };

  return (
    <AppContext.Provider value={contextData}>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={isLoading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      {children}
    </AppContext.Provider>
  );
};
