import { Grid } from "@mui/material";
import { grey } from "@mui/material/colors";
import React from "react";

const Modal = (props) => {
  return (
    <div className="modal d-block">
      <Grid container spacing={2}>
        <Grid item sx={{ bgcolor: "#f6f6f6" }} xs={12}>
          {props.children}
        </Grid>
      </Grid>
    </div>
  );
};

export default Modal;
