import React from "react";
import i1 from "assets/images/1.webp";
import i2 from "assets/images/2.webp";
import i3 from "assets/images/3.webp";
import s1 from "assets/images/s1.webp";
import s2 from "assets/images/s2.webp";
import s3 from "assets/images/s3.webp";
import s4 from "assets/images/s4.webp";
import s5 from "assets/images/s5.webp";
import s6 from "assets/images/s6.webp";
import g1 from "assets/images/g1.webp";
import g2 from "assets/images/g2.webp";
import w1 from "assets/images/w1.webp";
import w2 from "assets/images/w2.webp";

import PhotoAlbum from "react-photo-album";
import Lightbox from "yet-another-react-lightbox";
import "yet-another-react-lightbox/styles.css";
import { useState } from "react";
import Fullscreen from "yet-another-react-lightbox/plugins/fullscreen";
import Zoom from "yet-another-react-lightbox/plugins/zoom";
import "yet-another-react-lightbox/plugins/thumbnails.css";


const GallerySection = () => {
  const [index, setIndex] = useState(-1);
  const images = [
    {
      src: i1,
      imageFit: "cover",
      width: 800,
      height: 900,
    },
    {
      src: i2,
      imageFit: "cover",
      width: 800,
      height: 900,
    },
    {
      src: i3,
      imageFit: "cover",
      width: 800,
      height: 900,
    },
    {
      src: s1,
      imageFit: "cover",
      width: 800,
      height: 900,
    },
    {
      src: s2,
      imageFit: "cover",
      width: 800,
      height: 900,
    },
    {
      src: s3,
      imageFit: "cover",
      width: 800,
      height: 900,
    },
    {
      src: s4,
      imageFit: "cover",
      width: 800,
      height: 900,
    },
    {
      src: s5,
      imageFit: "cover",
      width: 800,
      height: 900,
    },
    {
      src: s6,
      imageFit: "cover",
      width: 800,
      height: 900,
    },
    {
      src: g1,
      imageFit: "cover",
      width: 800,
      height: 900,
    },
    {
      src: g2,
      imageFit: "cover",
      width: 800,
      height: 900,
    },
    {
      src: w1,
      imageFit: "cover",
      width: 800,
      height: 900,
    },
    {
      src: w2,
      imageFit: "cover",
      width: 800,
      height: 900,
    },
  ];

  return (
    <div name="gallery" className="w-full bg-[#0F0F0F]">
      <div className="pt-11 text-center">
        <h2 className="text-5xl text-stone-500 font-bold">Gallery</h2>
      </div>
      <div className="max-w-[1240px] m-auto min-h-screen p-4">
        <PhotoAlbum
          layout="columns"
          targetRowHeight={150}
          photos={images}         
          componentsProps={(containerWidth) => ({
            imageProps: {
              loading: (containerWidth || 0) > 600 ? "eager" : "lazy",
            },
          })}
          onClick={({ index }) => setIndex(index)}
        />
        <Lightbox
          slides={images}
          open={index >= 0}
          index={index}
          close={() => setIndex(-1)}
          plugins={[Fullscreen, Zoom]}
        />
      </div>
    </div>
  );
};

export default GallerySection;
