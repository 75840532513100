import { useTheme } from "@mui/material/styles";
import {
  Checkbox,
  FormControl,
  FormControlLabel,
  Grid,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Paper,
  Select,
  TextField,
} from "@mui/material";
import { grey } from "@mui/material/colors";
import Error from "common/components/Error";
import Heading from "common/components/Heading";
import React, { useContext, useEffect, useState } from "react";
import AppButton from "common/components/Button";
import api_service from "common/utils/Api";
import { useNavigate } from "react-router-dom";
import AuthContext from "context/AuthContext";
import { responseErrorMsg } from "common/utils/ResponseError";
import AppContext from "context/AppContext";

const UserForm = ({ title, id }) => {
  const theme = useTheme();
  const navigate = useNavigate();
  const { authTokens, logoutUser } = useContext(AuthContext);
  const { setIsLoading } = useContext(AppContext);
  const [errors, setErrors] = useState({});
  const [isDisabled, setIsDisabled] = useState(false);
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    password: "",
    roles: [],
    isEnabled: false,
  });

  useEffect(() => {
    const controller = new AbortController();
    const signal = controller.signal;
    if (id) getUserById(signal);

    return () => {
      controller.abort();
    };
  }, []);

  const getUserById = async (signal) => {
    try {
      setIsLoading(true);
      const response = await api_service.getUserById(authTokens, id, signal);
      if (response.status === 200) {
        setIsLoading(false);
        setFormData(response.data);
      }
    } catch (error) {
      setIsLoading(false);
      if (error?.name !== "CanceledError") {
        setErrors(responseErrorMsg(error.response, logoutUser));
      }
    }
  };

  const handleFormInputChange = (e) => {
    setErrors({});
    const { value, name } = e.target;
    if (e.target.name === "isEnabled")
      setFormData({ ...formData, [name]: e.target.checked });
    else setFormData({ ...formData, [name]: value });
  };

  const handleChange = (event) => {
    setErrors({});
    const {
      target: { value },
    } = event;
    let val = typeof value === "string" ? value.split(",") : value;
    setFormData({ ...formData, roles: val });
  };

  const onSubmit = (e) => {
    e.preventDefault();

    if (id) updateUser(formData);
    else registerUser(formData);
  };

  const updateUser = async (formData) => {
    try {
      setIsDisabled(true);
      const response = await api_service.updateUser(authTokens, formData);
      if (response.status === 200) {
        navigate("/account", {
          state: { message: response.data, success: true },
        });
      }
    } catch (error) {
      setIsDisabled(false);
      setErrors(responseErrorMsg(error.response, logoutUser));
    }
  };

  const registerUser = async (formData) => {
    try {
      setIsDisabled(true);
      const response = await api_service.registerUser(authTokens, formData);
      if (response.status === 201) {
        navigate("/account", {
          state: { message: response.data, success: true },
        });
      }
    } catch (error) {
      setIsDisabled(false);
      setErrors(responseErrorMsg(error.response, logoutUser));
    }
  };

  return (
    <>
      <Paper elevation={3} sx={{ m: 1, p: 4 }}>
        <Error text={errors?.message} />

        <Grid container spacing={0}>
          <Grid item xs={12} md={12}>
            <Heading
              variant={"h5"}
              title={title}
              sxProp={{
                m: 2,
                color: grey[500],
                textTransform: "uppercase",
                fontSize: "12pt",
              }}
            />
          </Grid>
        </Grid>
        <form noValidate onSubmit={onSubmit}>
          <Grid item container spacing={2}>
            <Grid item xs={12} md={6}>
              <TextField
                id="firstName"
                fullWidth
                color="success"
                autoComplete="off"
                label="First Name"
                variant="filled"
                name="firstName"
                value={formData?.firstName}
                margin="normal"
                onChange={(e) => handleFormInputChange(e)}
              />
              <Error text={errors?.firstName} />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                id="lastName"
                fullWidth
                color="success"
                autoComplete="off"
                label="Last Name"
                variant="filled"
                name="lastName"
                value={formData.lastName}
                margin="normal"
                onChange={(e) => handleFormInputChange(e)}
              />
              <Error text={errors?.lastName} />
            </Grid>
          </Grid>

          <Grid item container spacing={2}>
            <Grid item xs={12} md={6}>
              <TextField
                id="email"
                color="success"
                autoComplete="off"
                label="Email"
                margin="normal"
                fullWidth
                variant="filled"
                name="email"
                value={formData.email}
                onChange={(e) => handleFormInputChange(e)}
              />
              <Error text={errors?.email} />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                id="password"
                label="Password"
                color="success"
                autoComplete="off"
                variant="filled"
                name="password"
                type="password"
                value={formData?.msg?.password}
                margin="normal"
                fullWidth
                onChange={(e) => handleFormInputChange(e)}
              />
              <Error text={errors?.password} />
            </Grid>
          </Grid>

          <Grid item container spacing={2}>
            <Grid item xs={12} md={6}>
              <FormControl sx={{ m: 1, width: 300 }}>
                <InputLabel id="demo-multiple-name-label">Role(s)</InputLabel>
                <Select
                  id="roleId"
                  multiple
                  value={formData.roles}
                  onChange={handleChange}
                  input={<OutlinedInput label="Role" />}
                  MenuProps={MenuProps}
                >
                  {roles.map((role) => (
                    <MenuItem
                      key={role}
                      value={role}
                      style={getStyles(role, formData.roles, theme)}
                    >
                      {role}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <Error text={errors?.roles} />
            </Grid>
            <Grid item xs={12} md={6}>
              <FormControlLabel
                control={
                  <Checkbox
                    name="isEnabled"
                    checked={formData.isEnabled}
                    onChange={(e) => handleFormInputChange(e)}
                  />
                }
                label="Is Enabled"
              />
            </Grid>
            <Grid item xs={12}>
              <AppButton
                btnValue={"Save"}
                variant={"contained"}
                isDisabled={isDisabled}
                type={"submit"}
              />
            </Grid>
          </Grid>
        </form>
      </Paper>
    </>
  );
};

export default UserForm;

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const roles = ["ADMIN", "MANAGER"];

function getStyles(role, roleName, theme) {
  return {
    fontWeight:
      roleName.indexOf(role) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  };
}
