import Error from "common/components/Error";
import GenericTable from "common/components/GenericTable";
import Heading from "common/components/Heading";
import api_service from "common/utils/Api";
import { responseErrorMsg } from "common/utils/ResponseError";
import AppContext from "context/AppContext";
import AuthContext from "context/AuthContext";
import TableData from "job/components/TableData";
import React, { useEffect, useContext, useState } from "react";
import { useParams } from "react-router-dom";
import Moment from "moment";
import { styled } from "@mui/material/styles";
import { tableCellClasses } from "@mui/material/TableCell";

import { Link } from "react-router-dom";
import {
  Table,
  TableBody,
  TableCell,
  Grid,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Tooltip,
  Typography,
} from "@mui/material";
import { blue, green, grey, orange, red } from "@mui/material/colors";

import NFormat from "common/components//NumberFormat";

const PaymentDetails = () => {
  const { setIsLoading } = useContext(AppContext);
  const [errors, setErrors] = useState({});
  const { authTokens, logoutUser } = useContext(AuthContext);
  const [paymentDetails, setPaymentDetails] = useState([]);

  const { installerId } = useParams();
  useEffect(() => {
    const controller = new AbortController();
    const signal = controller.signal;
    getInstallerWorkInfo(signal);

    return () => {
      controller.abort();
    };
  }, []);

  const getInstallerWorkInfo = async (signal) => {
    try {
      setIsLoading(true);
      const response = await api_service.getInstallerWorkInfo(
        authTokens,
        signal,
        installerId
      );
      if (response.status === 200) {
        setIsLoading(false);
        setPaymentDetails(response.data);
      }
    } catch (error) {
      setIsLoading(false);
      if (error?.name !== "CanceledError") {
        setErrors(responseErrorMsg(error.response, logoutUser));
      }
    }
  };

  return (
    <>
      <div className="padding_1rem">
        <Grid
          container
          sx={{
            border: 1,
            m: 0,
            color: grey[900],
            borderStyle: "none none solid none",
          }}
        >
          <Grid item xs={12}>
            <Heading
              variant={"h3"}
              title={`Payment Details`}
              sxProp={{ fontWeight: 800, m: 2, p: 2, color: grey[600] }}
            />
          </Grid>
        </Grid>
        <TableContainer component={Paper}>
          {paymentDetails && (
            <Table sx={{ minWidth: 650 }} aria-label="job table">
              <TableHead>
                <TableRow>
                  <StyledTableCell>Job Date</StyledTableCell>
                  <StyledTableCell>
                    Builder<br></br>Job Address
                  </StyledTableCell>

                  <StyledTableCell sx={{ textAlign: "right" }}>
                    Installation Charge
                  </StyledTableCell>

                  <StyledTableCell>Installer(s)</StyledTableCell>
                </TableRow>
              </TableHead>
              {paymentDetails.length > 0 ? (
                <TableBody>
                  {paymentDetails.map((row, index) => (
                    <StyledTableRow key={row.jobId}>
                      <StyledTableCell>
                        {Moment(row.jobDate).format("YYYY-MM-DD")}
                      </StyledTableCell>
                      <StyledTableCell>
                        {row.builder.name}
                        <br></br>
                        <Tooltip title="Edit Job">
                          <Link
                            to={{
                              pathname: `/job/editJob/${row.jobId}`,
                            }}
                          >
                            {row.jobAddress}
                          </Link>
                        </Tooltip>
                      </StyledTableCell>

                      <StyledTableCell sx={{ textAlign: "right" }}>
                        <NFormat
                          prefix={"$"}
                          suffix={""}
                          number={row.installationFee}
                        />
                      </StyledTableCell>

                      <StyledTableCell>
                        <div>
                          {row.installers.map((item, index) => (
                            <div key={item.id}>
                              <div>{item.name}</div>
                            </div>
                          ))}
                        </div>
                      </StyledTableCell>
                    </StyledTableRow>
                  ))}
                </TableBody>
              ) : (
                <Typography variant="subtitle1" sx={{ m: 2, color: grey[500] }}>
                  No Data Available
                </Typography>
              )}
            </Table>
          )}
        </TableContainer>
      </div>
    </>
  );
};

export default PaymentDetails;

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: grey[500],
    fontWeight: 600,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
    color: grey[400],
    paddingRight: 1,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: grey[900],
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));
