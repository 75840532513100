import { Grid } from "@mui/material";
import React from "react";
import { grey, red } from "@mui/material/colors";

const ModalHeader = (props) => {
  return (
    <Grid container spacing={2}>
      <Grid item xs={12} sx={{ color: grey[700], mt: 5, p: 2, fontSize: 16 }}>
        <h1>{props.children}</h1>
        <hr></hr>
      </Grid>
    </Grid>
  );
};

export default ModalHeader;
