import { Email, Person, Update } from "@mui/icons-material";
import { Divider, Grid, Paper, TextField, Typography } from "@mui/material";
import { grey } from "@mui/material/colors";
import AlertMsg from "common/components/AlertMsg";
import AppButton from "common/components/Button";
import Error from "common/components/Error";
import Heading from "common/components/Heading";
import api_service from "common/utils/Api";
import { responseErrorMsg } from "common/utils/ResponseError";
import AppContext from "context/AppContext";
import AuthContext from "context/AuthContext";
import React, { useContext, useEffect, useState } from "react";

export default function Settings() {
  const { user, authTokens, logoutUser } = useContext(AuthContext);
  const [errors, setErrors] = useState({});
  const { setIsLoading } = useContext(AppContext);
  const [userInfo, setUserInfo] = useState({});
  const [successMsg, setSuccessMsg] = useState("");
  const [open, setOpen] = useState(false);
  const [form, setForm] = useState({
    oldPassword: "",
    newPassword: "",
    email: user,
  });

  useEffect(() => {
    const controller = new AbortController();
    const signal = controller.signal;
    getUserInfo(signal);

    return () => {
      controller.abort();
    };
  }, []);

  const getUserInfo = async (signal) => {
    try {
      setIsLoading(true);
      const response = await api_service.getUser(user, authTokens, signal);
      if (response.status === 200) {
        setIsLoading(false);
        setUserInfo(response.data);
      }
    } catch (error) {
      setIsLoading(false);
      if (error?.name !== "CanceledError") {
        setErrors(responseErrorMsg(error.response, logoutUser));
      }
    }
  };

  const handleChangeInput = (e) => {
    setErrors({});
    const { name, value } = e.target;
    setForm({ ...form, [name]: value });
  };

  const onSubmit = (e) => {
    e.preventDefault();
    updatePwd();
  };

  const updatePwd = async () => {
    try {
      const response = await api_service.updatePwd(authTokens, form);
      if (response.status === 200) {
        setSuccessMsg(response.data);
        setForm({ oldPassword: "", newPassword: "", email: user });
        setOpen(true);
      }
    } catch (error) {
      if (error.response.data.length) setOpen(true);

      setErrors(responseErrorMsg(error.response, logoutUser));
    }

    setTimeout(() => {
      setOpen(false);
    }, 4000);
  };

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Heading
            variant={"h3"}
            title={"Settings"}
            sxProp={{
              fontWeight: 800,
              m: 0,
              p: 2,
              color: grey[600],
              bgcolor: "#FBFBFB",
            }}
          />
          <Divider />
        </Grid>
      </Grid>

      <AlertMsg
        message={errors?.message ? errors.message : successMsg}
        open={open}
        severity={errors?.message ? "error" : "success"}
      />

      <form noValidate onSubmit={onSubmit}>
        <Grid container spacing={2}>
          <Grid item xs={12} sx={{m:2,p:2}}>
            <Paper elevation={2}>
              <Grid item xs={12} sx={{ m: 2,p:2, color: grey[700] }}>
                <Typography variant="subtitle1">
                  <Person /> {userInfo.firstName + " " + userInfo.lastName}
                </Typography>
              </Grid>
              <Grid item xs={12} sx={{ m: 2,p:2, color: grey[700] }}>
                <Typography variant="subtitle1">
                  <Email /> {userInfo.email}
                </Typography>
              </Grid>

              <Grid item xs={12} sx={{ p: 2}}>
                <Heading
                  variant={"h5"}
                  title={"Change Password"}
                  sxProp={{
                    m: 2,
                    color: grey[500],
                    textTransform: "uppercase",
                    fontSize: "12pt",
                  }}
                />
              </Grid>
              <Grid item xs={12} sx={{ p:2 }}>
                <TextField
                  value={form.oldPassword}
                  type="password"
                  label="Old Password"
                  fullWidth
                  autoComplete="off"
                  name="oldPassword"
                  color="success"
                  variant="filled"
                  onChange={(e) => handleChangeInput(e)}
                />
                <Error text={errors?.oldPassword} />
              </Grid>
              <Grid item xs={12} sx={{ p:2 }}>
                <TextField
                  value={form.newPassword}
                  type="password"
                  label="New Password"
                  fullWidth
                  autoComplete="off"
                  name="newPassword"
                  color="success"
                  variant="filled"
                  onChange={(e) => handleChangeInput(e)}
                />
                <Error text={errors?.newPassword} />
              </Grid>
              <Grid item xs={12} sx={{px:2, py:4 }}>
                <AppButton
                  icon={<Update />}
                  btnValue={"Update"}
                  type={"submit"}
                  variant={"contained"}
                />
              </Grid>
            </Paper>
          </Grid>
        </Grid>
      </form>
    </>
  );
}
