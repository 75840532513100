import React, { useState, useContext, useEffect } from "react";
import { NavLink } from "react-router-dom";
import Footer from "footer/components/Footer";
import AuthContext from "context/AuthContext";
import { Grid } from "@mui/material";
import {
  Dashboard,
  Engineering,
  Handyman,
  HomeWork,
  Inventory,
  ManageAccounts,
  MonetizationOn,
  Settings,
} from "@mui/icons-material";
import common from "common/utils/Common";
import logo from "assets/images/logo_ARK.png";

const menuItems = [
  {
    path: "/home",
    icon: <Dashboard />,
    name: "Dashboard",
    role: common.ROLE_MANAGER,
  },
  {
    path: "/job",
    icon: <HomeWork />,
    name: "Job",
    role: common.ROLE_MANAGER,
  },
  {
    path: "/payment",
    icon: <MonetizationOn />,
    name: "Payment Info",
    role: common.ROLE_MANAGER,
  },
  {
    path: "/builder",
    icon: <Engineering />,
    name: "Builder",
    role: common.ROLE_MANAGER,
  },
  {
    path: "/installer",
    icon: <Handyman />,
    name: "Installer",
    role: common.ROLE_MANAGER,
  },
  {
    path: "/supplier",
    icon: <Inventory />,
    name: "Vendor",
    role: common.ROLE_MANAGER,
  },
  {
    path: "/account",
    icon: <ManageAccounts />,
    name: "Account",
    role: common.ROLE_ADMIN,
  },
  {
    path: "/settings",
    icon: <Settings />,
    name: "Settings",
    role: common.ROLE_MANAGER,
  },
];

const Sidebar = () => {
  const { role } = useContext(AuthContext);
  const [menuOption, setMenuOption] = useState([]);

  useEffect(() => {
    let menuArr = [];
    menuItems.forEach((item) => {
      if (role.includes(item.role)) {
        menuArr.push(item);
      }
    });
    setMenuOption(menuArr);
  }, []);

  return (
    <>
      <Grid container spacing={2}>
        <Grid
          item
          xs={12}
          sx={{
            display: "flex",
            justifyContent: "center",
            mt: 2,                      
          }}
        >
          <img
            src={logo}
            loading="lazy"
            className="w-24 px-2 py-1"
            alt="logo"
          />
        </Grid>

        <Grid item xs={12} sx={{ mt: 2 }}>
          {menuOption?.map((item, index) => (
            <div key={index}>
              <NavLink to={item.path} className="link">
                <>
                  <div className="icon">{item.icon}</div>
                  <div className="link_text">
                    <div>{item.name}</div>
                  </div>
                </>
              </NavLink>
            </div>
          ))}
        </Grid>
      </Grid>
      <div className="sidebar-footer">
        <Footer />
      </div>
    </>
  );
};

export default Sidebar;
