import { Tooltip, TableCell } from "@mui/material";
import React from "react";
import { Link } from "react-router-dom";
import NumberFormat from "./NumberFormat";
import { ArrowDownwardRounded, ArrowUpwardRounded } from "@mui/icons-material";
import { tableCellClasses } from "@mui/material/TableCell";
import { green, grey, red } from "@mui/material/colors";
import AppButton from "./Button";
import { styled } from "@mui/material/styles";

const Data = ({ data, toolTipTxt, path, row, rowIndex }) => {
  return (
    <>
      {row &&
        data?.map((item, index) => (
          <StyledTableCell
            key={rowIndex + index}
            align={
              item === "totalEarned" ||
              item === "profitLossAtm" ||
              item === "totalExpense" ||
              item === "paymentAmt"
                ? "right"
                : "left"
            }
          >
            {item === "name" || item === "firstName" ? (
              <Tooltip title={toolTipTxt}>
                <Link
                  to={{
                    pathname: `${path}/${row.id}`,
                  }}
                >
                  {row[item]}
                </Link>
              </Tooltip>
            ) : item === "address" ? (
              <>
                <div>{row.address}</div>
                <div>
                  {row.city},&nbsp;{row.province}, &nbsp;
                  {row.postal_code}
                </div>
              </>
            ) : item === "total" ||
              item === "totalExpense" ||
              item === "totalEarned" ||
              item === "glassExpense" ||
              item === "paymentAmt" ? (
              <div style={{ display: "flex", justifyContent: "flex-end" }}>
                <NumberFormat prefix={"$"} number={row[item]} />
              </div>
            ) : item === "profitLossAtm" ? (
              <div
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                }}
              >
                <div>
                  <NumberFormat prefix={"$"} number={row[item]} />
                </div>
                <div>
                  {row[item] > 0 ? (
                    <ArrowUpwardRounded sx={{ color: green[500] }} />
                  ) : row[item] < 0 ? (
                    <ArrowDownwardRounded sx={{ color: red[500] }} />
                  ) : (
                    ""
                  )}
                </div>
              </div>
            ) : item === "roles" ? (
              <>
                {row[item].map((role, roleIndex) => (
                  <div key={roleIndex}>{role}</div>
                ))}
              </>
            ) : item === "isEnabled" ? (
              <>{row[item] ? "Yes" : "No"}</>
            ) : item === "builder" ? (
              <> {row.builder.name}</>
            ) : item === "installers" ? (
              <>
                {row[item].map((i, installerIndex) => (
                 
                    <div key={installerIndex}>{i.name}</div>
                 
                ))}
              </>
            ) : (
              <>{row[item]}</>
            )}
          </StyledTableCell>
        ))}
    </>
  );
};

export default Data;

export const StyledTableCell = styled(TableCell)(({ theme }) => ({ 
  [`&.${tableCellClasses.body}`]: {
    fontSize: 12,
    color: grey[800],
  },
}));