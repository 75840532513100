import { Alert, Collapse } from '@mui/material';
import React from 'react'

const AlertMsg = ({ message, open, severity }) => {
  return (
    <div>
      <Collapse in={open}>
        <Alert          
          severity={severity}
          sx={{ mb: 2 }}
        >
          {message}
        </Alert>
      </Collapse>
    </div>
  );
};

export default AlertMsg


