import React, { createContext, useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import jwtDecode from "jwt-decode";
import common from "common/utils/Common";
import { responseErrorMsg } from "common/utils/ResponseError";

const AuthContext = createContext();

export default AuthContext;

export const AuthProvider = ({ children }) => {
  const [authTokens, setAuthTokens] = useState(() =>
    localStorage.getItem("authTokens")
      ? JSON.parse(localStorage.getItem("authTokens"))
      : null
  );

  const [user, setUser] = useState(null);
  const [role, setRole] = useState([]);
  const [errors, setErrors] = useState({});
  const navigate = useNavigate();

  const loginUser = async (e) => {
    e.preventDefault();

    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };

    const data = JSON.stringify({
      email: e.target.email.value,
      password: e.target.password.value,
    });
    try {
      const response = await axios.post("/api/auth/token", data, config);
      if (response.status === 200) {
        setAuthTokens(response.data);
        localStorage.setItem("authTokens", JSON.stringify(response.data));
        const decoded = jwtDecode(response.data);
        setUser(decoded.sub);
        let scopeArr = decoded.scope.split(" ").sort();

        let roles = [];
        scopeArr.forEach((e) => {
          if (e.substring(0, 4) === "ROLE") roles.push(e);
        });
        setRole(roles);

        if (roles.length === 1 && roles[0] === common.ROLE_ADMIN)
          navigate("/account");
        else navigate("/home");
      }
    } catch (error) {     
      if (error.response.status === 401) {
        setErrors({...errors, ['message']: 'Invalid email and/or password'});
      } else {
        setErrors(error.response.data);
      }
    }
  };

  let logoutUser = async () => {
    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + String(authTokens),
        },
      };

      const response = await axios.post("/logout", null, config);

      if (response.status === 200) {
        setAuthTokens(null);
        setUser(null);
        localStorage.removeItem("authTokens");
        navigate("/login");
      }
    } catch (error) {
      setErrors(error.response.data);
    }
  };

  let contextData = {
    loginUser: loginUser,
    logoutUser: logoutUser,
    user: user,
    errors: errors,
    setErrors: setErrors,
    authTokens: authTokens,
    role: role,
  };

  return (
    <AuthContext.Provider value={contextData}>{children}</AuthContext.Provider>
  );
};
