import { Typography } from '@mui/material'
import React from 'react'

const Heading = ({title, variant,sxProp}) => {
  return (
    <Typography
      variant={variant}     
      sx={sxProp}
    >
      {title}
    </Typography>
  );
}

export default Heading
