import React, { useEffect, useState, useContext } from "react";
import AuthContext from "context/AuthContext";
import AppButton from "common/components/Button";
import { Box, Divider, Grid, Tab, Tabs } from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import api_service from "common/utils/Api";
import ModalService from "modals/service/ModalService";
import ConfirmModal from "common/components/ConfirmModal";
import ModalRoot from "modals/components/ModalRoot";
import AppContext from "context/AppContext";
import TableData from "job/components/TableData";
import { blue, green, grey, orange, red } from "@mui/material/colors";
import Heading from "common/components/Heading";
import {
  AddBox,
  CheckCircleOutline,
  DeleteForever,
  Paid,
  PendingOutlined,
} from "@mui/icons-material";
import PropTypes from "prop-types";
import { responseErrorMsg } from "common/utils/ResponseError";
import common from "common/utils/Common";
import AlertMsg from "common/components/AlertMsg";

const Job = () => {
  const [page, SetPage] = useState(0);
  const [pageSize, SetPageSize] = useState(100);
  const { authTokens, logoutUser } = useContext(AuthContext);
  const navigate = useNavigate();
  const [jobsData, setJobsData] = useState({});
  const { value, setIsLoading } = useContext(AppContext);
  const [jobIds, setJobIds] = useState([]);
  const [isAscOrder, setIsAscOrder] = useState(true);
  const [builders, setBuilders] = useState([]);
  const [jobStatus, setJobStatus] = useState("I");
  const [tabValue, setTabValue] = useState(0);
  const [errors, setErrors] = useState({});
  const [open, setOpen] = useState(false);
  const location = useLocation();
  const [searchform, setSearchForm] = useState({
    jobDate: "",
    builder: {},
    jobAddress: "",
  });

  useEffect(() => {
    const controller = new AbortController();
    const signal = controller.signal;
    getBuilders(signal);

    return () => {
      controller.abort();
    };
  }, []);

  useEffect(() => {
    if (location?.state?.message || errors?.message) setOpen(true);

    setTimeout(() => {
      setOpen(false);
    }, 4000);
  }, [location, errors.message]);

  useEffect(() => {
    const controller = new AbortController();
    const signal = controller.signal;
    getAllJobs(page, pageSize, jobStatus, signal);

    return () => {
      controller.abort();
    };
  }, [value]);

  const getBuilders = async (signal) => {
    try {
      setIsLoading(true);
      const response = await api_service.getBuilders(authTokens, signal);
      if (response.status === 200) {
        setIsLoading(false);
        setBuilders(response.data);
      }
    } catch (error) {
      setIsLoading(false);
      if (error?.name !== "CanceledError") {
        setErrors(responseErrorMsg(error.response, logoutUser));
      }
    }
  };

  const getAllJobs = async (page, size, jobStatus, signal) => {
    try {
      setIsLoading(true);
      const response = await api_service.getJobs(
        authTokens,
        page,
        size,
        jobStatus,
        signal
      );
      if (response.status === 200) {
        setIsLoading(false);
        setJobsData(response.data);
        setJobIds([]);
      }
    } catch (error) {
      setIsLoading(false);
      if (error?.name !== "CanceledError") {
        setErrors(responseErrorMsg(error.response, logoutUser));
      }
    }
  };

  const handleClickNew = () => {
    navigate("/job/newJob");
  };

  const handleCheckboxChange = (e) => {
    const { value, checked } = e.target;
    let intValue = parseInt(value);
    if (checked) setJobIds([...jobIds, intValue]);
    else setJobIds(jobIds.filter((i) => i !== value));
  };

  const handleChangePageActive = (e, v) => {
    let pg = parseInt(v);
    SetPage(pg);
    getAllJobs(pg, pageSize, jobStatus);
  };

  const handleChangeRowsPerPage = (e) => {
    let pgSize = parseInt(e.target.value);
    SetPageSize(pgSize);
    getAllJobs(page, pgSize, jobStatus);
  };

  const handleClickPaid = async (e) => {
    if (jobIds.length > 0) {
      try {
        setIsLoading(true);
        const response = await api_service.updatePaidStatus(authTokens, jobIds);
        if (response.status === 200) {
          setIsLoading(false);
          getAllJobs(page, pageSize, jobStatus);
        }
      } catch (error) {
        setIsLoading(false);
        setErrors(responseErrorMsg(error.response, logoutUser));
      }
    }
  };
  const handleClickCompleted = async (e) => {
    updateJobStatus("C");
  };
  const handleClickInProgress = async (e) => {
    updateJobStatus("I");
  };

  const updateJobStatus = async (status) => {
    if (jobIds.length > 0) {
      try {
        setIsLoading(true);
        const response = await api_service.updateJobStatus(
          authTokens,
          jobIds,
          status
        );

        if (response.status === 200) {
          setIsLoading(false);
          getAllJobs(page, pageSize, jobStatus);
        }
      } catch (error) {
        setIsLoading(false);
        setErrors(responseErrorMsg(error.response, logoutUser));
      }
    }
  };

  const handleClickSorting = async (e, fieldName) => {
    setIsAscOrder(!isAscOrder);
    if (fieldName) {
      try {
        setIsLoading(true);
        const response = await api_service.getJobByFieldName(
          authTokens,
          fieldName,
          page,
          pageSize,
          isAscOrder ? "ASC" : "DESC",
          jobStatus
        );
        if (response.status === 200) {
          setIsLoading(false);
          setJobsData(response.data);
        }
      } catch (error) {
        setIsLoading(false);
        setErrors(responseErrorMsg(error.response, logoutUser));
      }
    }
  };

  const handleSearchFormInputChange = (e) => {
    const { value, name } = e.target;
    setSearchForm({ ...searchform, [name]: value });
  };

  const onSubmitSearch = (e) => {
    e.preventDefault();
    if (
      searchform.jobAddress === "" &&
      searchform.builderName === "" &&
      searchform.jobDate === ""
    ) {
      return;
    }

    searchByUserInput(searchform, page, pageSize);
  };

  const searchByUserInput = async (searchform, page, pageSize) => {
    try {
      setIsLoading(true);
      const response = await api_service.searchJobByUserInput(
        authTokens,
        searchform,
        page,
        pageSize,
        jobStatus
      );
      if (response.status === 200) {
        setIsLoading(false);
        setJobsData(response.data);
      }
    } catch (error) {
      setIsLoading(false);
      setErrors(responseErrorMsg(error.response, logoutUser));
    }
  };

  const handleClickClear = () => {
    setSearchForm({
      jobDate: "",
      builder: {},
      jobAddress: "",
    });
    getAllJobs(page, pageSize, jobStatus);
  };

  const handleClickDelete = () => {
    if (jobIds.length > 0) {
      deleteJobModal(jobIds);
      setJobIds([]);
    }
  };

  const handleChange = (event, newValue) => {
  
    if (newValue === 0) {
      getAllJobs(page, pageSize, common.INPROGRESS);
      setJobStatus(common.INPROGRESS);
    } else if (newValue === 1) {
      getAllJobs(page, pageSize, common.COMPLETED);
      setJobStatus(common.COMPLETED);
    }

    setTabValue(newValue);
  };

  return (
    <>
      <ModalRoot />

      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Heading
            variant={"h3"}
            title={`Job [${jobsData?.content?.length}]`}
            sxProp={{
              fontWeight: 800,
              m: 0,
              p: 2,
              color: grey[600],
              bgcolor: "#FBFBFB",
            }}
          />
          <Divider />
        </Grid>
      </Grid>

      <AlertMsg
        message={errors?.message ? errors.message : location?.state?.message}
        open={open}
        severity={errors?.message ? "error" : "success"}
      />

      <Grid container spacing={2}>
        <Grid item xs={12} sx={{ m: 2 }}>
          <AppButton
            icon={<AddBox />}
            btnValue={"Add New"}
            type="button"
            variant={"contained"}
            handleClicked={handleClickNew}
          ></AppButton>
        </Grid>
        <Grid item container spacing={2}>
          <Grid item xs={6} md={2}>
            <AppButton
              icon={<PendingOutlined />}
              btnValue={"In Progress"}
              type="button"
              isFullwidth={true}
              size={"small"}
              sx={{ ml: 1 }}
              variant={"contained"}
              handleClicked={handleClickInProgress}
            ></AppButton>
          </Grid>
          <Grid item xs={6} md={2}>
            <AppButton
              icon={<CheckCircleOutline />}
              btnValue={"Completed"}
              isFullwidth={true}
              type="button"
              size={"small"}
              variant={"contained"}
              handleClicked={handleClickCompleted}
            ></AppButton>
          </Grid>
          <Grid item xs={6} md={2}>
            <AppButton
              icon={<Paid />}
              btnValue={"Paid"}
              isFullwidth={true}
              type="button"
              size={"small"}
              variant={"contained"}
              handleClicked={handleClickPaid}
            ></AppButton>
          </Grid>
          <Grid item xs={6} md={2}>
            <AppButton
              icon={<DeleteForever />}
              btnValue={"Delete"}
              size={"small"}
              isFullwidth={true}
              variant={"contained"}
              type="button"
              handleClicked={handleClickDelete}
            ></AppButton>
          </Grid>
        </Grid>
      </Grid>

      <Box sx={{ width: "100%", mt: 8 }}>
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <Tabs
            value={tabValue}
            onChange={handleChange}
            aria-label="Job status tabs"
            textColor="inherit"
            indicatorColor="secondary"
          >
            <Tab label="In Progress" {...a11yProps(0)} />
            <Tab label="Completed" {...a11yProps(1)} />
          </Tabs>
        </Box>
        <CustomTabPanel value={tabValue} index={0}>
          <TableData
            data={jobsData}
            handleCheckboxChange={handleCheckboxChange}
            deleteJobModal={deleteJobModal}
            handleChangePage={handleChangePageActive}
            page={page}
            pageSize={pageSize}
            isAscOrder={isAscOrder}
            handleChangeRowsPerPage={handleChangeRowsPerPage}
            handleClickSorting={handleClickSorting}
            handleSearchFormInputChange={handleSearchFormInputChange}
            onSubmitSearch={onSubmitSearch}
            searchForm={searchform}
            handleClickClear={handleClickClear}
            setSearchForm={setSearchForm}
            builders={builders}
            jobIds={jobIds}
          />
        </CustomTabPanel>
        <CustomTabPanel value={tabValue} index={1}>
          <TableData
            data={jobsData}
            handleCheckboxChange={handleCheckboxChange}
            deleteJobModal={deleteJobModal}
            handleChangePage={handleChangePageActive}
            page={page}
            pageSize={pageSize}
            isAscOrder={isAscOrder}
            handleChangeRowsPerPage={handleChangeRowsPerPage}
            handleClickSorting={handleClickSorting}
            handleSearchFormInputChange={handleSearchFormInputChange}
            onSubmitSearch={onSubmitSearch}
            searchForm={searchform}
            handleClickClear={handleClickClear}
            setSearchForm={setSearchForm}
            builders={builders}
            jobIds={jobIds}
          />
        </CustomTabPanel>
      </Box>
    </>
  );
};

export default Job;

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}      
    >
      {value === index && (
        <Box sx={{ p: 2 }}>
          <div>{children}</div>
        </Box>
      )}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const deleteJobModal = (jobIds) => {
  const props = {
    message: "Are you sure you want to delete the selected job(s) ",
    placeholder: null,
    jobIds: jobIds,
    type: "job",
    title: "Delete Job Confirmation",
  };
  ModalService.open(ConfirmModal, props);
};
