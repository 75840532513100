export const responseErrorMsg = (response, logoutUser) => {
  
  let err = {};
  if (!response?.data) {
    switch (response?.status) {
      case 400:
        err["message"] = "Invalid data. Please provide a valid inputs";
        break;
      case 401:
        err["message"] = "Unauthorize access";
        logoutUser();
        break;
      case 403:
        err["message"] = "Bad Request";
        break;
      case 404:
        err["message"] = "Page not found";
        break;
      default:
        err["message"] = "Error! Please contact system administrator.";
    }
  } else {
    if (typeof response.data === "string") {
      err["message"] = response.data;
    } else if (response.status === 500) {
      logoutUser();
    } else {
      err = response.data;
    }
  }

  return err;
};
