import { Typography } from "@mui/material";
import { blue, green, orange } from "@mui/material/colors";
import React from "react";
import common from 'common/utils/Common'

const Status = ({ jobStatus }) => {
  return (
    <>
      {jobStatus == common.COMPLETED ? (
        <Typography variant="subtitle1" sx={{ color: green[600] }}>
          Completed
        </Typography>
      ) : jobStatus == common.INPROGRESS ? (
        <>
          <Typography variant="subtitle1" sx={{ color: orange[600] }}>
            In Progress
          </Typography>
        </>
      ) : (
        <>
          <Typography variant="subtitle1" sx={{ color: blue[600] }}>
            New
          </Typography>
        </>
      )}
    </>
  );
};

export default Status;
