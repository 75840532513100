import React from "react";
import { DebounceInput } from "react-debounce-input";
import { Paper, TextField, Grid } from "@mui/material";
import InputMask from "react-input-mask";
import Service from "common/utils/Common";
import SelectOptions from "common/components/Select";
import Heading from "common/components/Heading";
import { grey } from "@mui/material/colors";
import Error from "common/components/Error";

const BasicInfo = ({
  title,
  handleFormInputChange,
  errors,
  formData,
  addressSuggestion,
  handleOnClick,
}) => {
  return (
    <div>
      <Paper elevation={3} sx={{ m: 1, p: 4 }}>
        <Grid container spacing={0}>
          <Grid item xs={12} md={12}>
            <div>
              <Heading
                variant={"h5"}
                title={title}
                sxProp={{
                  m: 2,
                  color: grey[500],
                  textTransform: "uppercase",
                  fontSize: "12pt",
                }}
              />
            </div>
          </Grid>
        </Grid>

        <Error text={errors?.message} />

        <Grid item container spacing={2}>
          <Grid item xs={12} md={6}>
            <TextField
              id="name"
              fullWidth
              color="success"
              autoComplete="on"
              label="Name"
              variant="filled"
              name="name"
              value={formData.name}
              margin="normal"
              onChange={(e) => handleFormInputChange(e)}
            />
            <Error text={errors?.name} />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              id="email"
              color="success"
              autoComplete="on"
              label="Email"
              margin="normal"
              fullWidth
              variant="filled"
              name="email"
              value={formData.email}
              onChange={(e) => handleFormInputChange(e)}
            />
            <Error text={errors?.email} />
          </Grid>
        </Grid>
        <Grid item container spacing={2}>
          <Grid item xs={12} md={6}>
            <InputMask
              mask="(+1) 999 999 9999"
              value={formData.phone}
              maskChar={null}
              onChange={(e) => handleFormInputChange(e)}
            >
              {() => (
                <TextField
                  fullWidth
                  color="success"
                  id="phone"
                  autoComplete="on"
                  label="Phone"
                  name="phone"
                  variant="filled"
                  margin="normal"
                />
              )}
            </InputMask>
            <Error text={errors?.phone} />
          </Grid>
          <Grid item xs={12} md={6} sx={{ mt: 1 }}>
            <div>
              <DebounceInput
                className="search-address"
                debounceTimeout={500}
                placeholder="Address"
                name="address"
                value={formData.address}
                onChange={(e) => handleFormInputChange(e)}
                autoComplete="off"
              />
              <Error text={errors?.address} />

              {addressSuggestion.length > 0 && (
                <div className="suggestionBox">
                  {addressSuggestion.map((v, i) => {
                    return (
                      <div
                        key={i}
                        className="suggestion-result"
                        onClick={(e) => handleOnClick(v.text)}
                      >
                        {v.text}
                      </div>
                    );
                  })}
                </div>
              )}
            </div>
          </Grid>
        </Grid>

        <Grid item container spacing={2}>
          <Grid item xs={12} md={6}>
            <TextField
              id="city"
              label="City"
              color="success"
              autoComplete="off"
              variant="filled"
              name="city"
              disabled={true}
              value={formData.city}
              margin="normal"
              fullWidth
              onChange={(e) => handleFormInputChange(e)}
            />
          </Grid>
          <Grid item xs={12} md={6} sx={{ mt: 4 }}>
            <SelectOptions
              value={
                Service.province[
                  Service.getIndex(formData.province.id, Service.province)
                ] || ""
              }
              handleFormInputChange={handleFormInputChange}
              options={Service.province}
              name="province"
              variant={"filled"}
              isDisabled={true}
              label="Select Province"
              errors={errors?.provinceError}
            ></SelectOptions>
          </Grid>
        </Grid>
        <Grid item container spacing={2}>
          <Grid item xs={12} md={6}>
            <TextField
              id="postalCode"
              color="success"
              label="Postal Code"
              variant="filled"
              autoComplete="off"
              disabled={true}
              name="postalCode"
              value={formData.postalCode || ""}
              margin="normal"
              fullWidth
              onChange={(e) => handleFormInputChange(e)}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              id="country"
              label="Country"
              color="success"
              autoComplete="off"
              variant="filled"
              name="country"
              value={formData.country}
              margin="normal"
              fullWidth
              disabled={true}
              onChange={(e) => handleFormInputChange(e)}
            />
          </Grid>
        </Grid>
      </Paper>
    </div>
  );
};

export default BasicInfo;
