import React from "react";
import { useParams } from "react-router-dom";
import Heading from "common/components/Heading";
import { grey } from "@mui/material/colors";
import { PersonOrgForm } from "common/components/PersonOrgForm";

const EditBuilder = () => {
  const { builderId } = useParams();
  return (
    <div>
      <div>
        <div>
          <Heading
            variant={"h3"}
            title={"Edit Builder"}
            sxProp={{ fontWeight: 800, m: 2, p: 2, color: grey[600] }}
          />
        </div>
        <PersonOrgForm id={builderId} formName={"builder"} />
      </div>
    </div>
  );
};

export default EditBuilder;
