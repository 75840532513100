import React from "react";
import logo from "assets/images/logo_ARK.png";
import { Link } from "react-router-dom";
import { Home } from "@mui/icons-material";

const Header = () => {
  return (
    <>
      <div className="w-screen h-[90px] z-10 bg-[#FBFBFB] fixed drop-shadow-2xl">
        <div className="px-2 flex justify-between items-center w-full h-full">
          <div className="flex items-center px-2">
            <img
              src={logo}
              alt="logo"
              loading="lazy"
              className="w-24 px-2 opacity-90"
            />
          </div>

          <div className="md:flex pr-4">
            <ul className="md:flex text-stone-500">
              <li>
                <Link className="cursor-pointer hover:text-amber-400" to="/">
                  <Home/>
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </>
  );
};

export default Header;
