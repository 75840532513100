import { Typography } from "@mui/material";
import { red } from "@mui/material/colors";
import React from "react";

const Error = ({ text }) => {
  return (
    <>
      <Typography sx={{ color: red[600], m: 0.5, fontSize: "10pt" }}>
        {text}
      </Typography>
    </>
  );
};

export default Error;
