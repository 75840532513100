import React from "react";
import Button from "@mui/material/Button";

const AppButton = ({
  icon,
  btnValue,
  type,
  handleClicked,
  endIcon,
  isDisabled,
  size,
  isFullwidth,
  variant,
  sx
}) => {
  return (
    <>
      <Button        
        startIcon={icon}
        endIcon={endIcon}
        type={type}      
        size={size} 
        sx={sx}
        fullWidth={isFullwidth}
        disabled={isDisabled ? true : false}
        variant={variant}
        onClick={type === "button" ? (e) => handleClicked(e) : null}
      >
        {btnValue}
      </Button>
    </>
  );
};

export default AppButton;
