export const multiErrorsCheck = (originalObj, arrName) => {
  let keys = Object.keys(originalObj)
    .filter((key) => key.includes(arrName))
    .sort();

  let filteredObj = {};
  keys.forEach((i) => {
    filteredObj[i] = originalObj[i];
  });
  return getArrayFromKeys(filteredObj, keys, null, false);
};

export const multiErrorsCheckForLevels = (originalObj, arrName) => {
  let keys = Object.keys(originalObj)
    .filter((key) => key.includes(arrName))
    .sort();

  let filteredObj = {};
  keys.forEach((i) => {
    filteredObj[i] = originalObj[i];
  });

  let outerI = [];
  let arrayList = [];

  keys.forEach((i) => {
    outerI.push(i.match(/\d+/g).map(Number)[0]);
  });

  let uniqueOuterIndexSorted = Array.from(new Set(outerI)).sort();

  for (
    let oi = 0;
    oi <= uniqueOuterIndexSorted[uniqueOuterIndexSorted.length - 1];
    oi++
  ) {
    let idxKeys = firstIndexInfo(keys).filter((key) => key.includes(oi));

    if (idxKeys.length > 0) {
      uniqueOuterIndexSorted.forEach((j) => {
        if (oi == j) {
          let p_arr = [];
          keys.forEach((val) => {
            let v = val.split(".")[0].match(/\d+/g).map(Number)[0];

            if (j === v) {
              p_arr.push(val);
            }
          });
          arrayList.push(getDetails(filteredObj, p_arr));
        }
      });
    } else {
      arrayList.push([]);
    }
  }
  return arrayList;
};

function getDetails(filteredObj, keys) {
  let keyArr = [];

  keys.forEach((i) => {
    let keySplitArr = i.split(".");
    keyArr.push(keySplitArr[1] + "." + keySplitArr[2]);
  });

  return getArrayFromKeys(filteredObj, keyArr, keys, true);
}

function firstIndexInfo(keys) {
  let keyArr = [];
  keys.forEach((i) => {
    let keySplitArr = i.split(".");
    keyArr.push(keySplitArr[0]);
  });
  return keyArr;
}

function getArrayFromKeys(filteredObj, keys, fullkeys, isMultiLevel) {
  let arr = [];
  let indexArr = [];

  keys.forEach((i) => {
    indexArr.push(i.match(/\d+/g).map(Number)[0]);
  });

  let uniqueArrIndexSorted = Array.from(new Set(indexArr)).sort();

  for (
    let idx = 0;
    idx <= uniqueArrIndexSorted[uniqueArrIndexSorted.length - 1];
    idx++
  ) {
    let idxKeys = keys.filter((key) => key.includes(idx));
    let obj = {};
    if (idxKeys.length > 0) {
      idxKeys.forEach((i, index) => {
        const b = i.split(".");
        obj[b[1]] = isMultiLevel
          ? filteredObj[fullkeys[index]]
          : filteredObj[i];
        arr[idx] = obj;
      });
    } else {
      arr[idx] = obj;
    }
  }

  return arr;
}
