import React from "react";
import about from "assets/images/about.webp";
import {
  CarpenterOutlined,
  HandymanOutlined,
  SquareFootOutlined,
} from "@mui/icons-material";
import { Paper, Typography } from "@mui/material";
import { amber, grey, yellow } from "@mui/material/colors";

const About = () => {
  return (
    <div name="about" className="w-full">
      <div className="hidden lg:block custom-shape-divider">
        <svg
          data-name="Layer 1"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 1200 120"
          preserveAspectRatio="none"
        >
          <path
            d="M321.39,56.44c58-10.79,114.16-30.13,172-41.86,82.39-16.72,168.19-17.73,250.45-.39C823.78,31,906.67,72,985.66,92.83c70.05,18.48,146.53,26.09,214.34,3V0H0V27.35A600.21,600.21,0,0,0,321.39,56.44Z"
            className="shape-fill"
          ></path>
        </svg>
      </div>
      <div className="max-w-[1240px] m-auto min-h-screen">
        <div className="flex flex-col bg-[#fff] sm:flex-row items-center">
          <div className="md:max-w-[50vw]">
            <img
              className="w-full h-full z-10"
              loading="lazy"
              src={about}
              alt="about"
            />
          </div>
          <div className="w-full p-1 text-center">
            <h2 className="text-5xl text-gray-700 p-4 font-bold">About</h2>
            <Typography
              variant="body1"
              sx={{ paddingY: "1rem", color: grey[900] }}
            >
              At Ark Railings, we believe in more than just constructing
              railings, we're dedicated to crafting connections. Founded in 2022
              and nestled in the heart of Edmonton, Alberta, we're your trusted
              partner for premium railing installations that blend artistry,
              functionality, and safety seamlessly.
            </Typography>
            <Typography
              variant="body1"
              sx={{ paddingY: "1rem", color: grey[900] }}
            >
              Whether you're seeking a classic wood, a sleek glass, or a modern
              metal railing, Ark Railings is your partner of choice. Join us in
              creating breathtaking spaces that elevate aesthetics, enhance
              safety, and stand as a testament to our dedication to perfection.
              Let us embark on a journey to transform your space into a work of
              art. Contact us today to set your project in motion.
            </Typography>
          </div>
        </div>
        <div className="text-center text-4xl p-8 font-bold text-gray-700">
          Our Services
        </div>

        <div className="flex flex-col lg:flex-row items-center justify-center gap-6 pb-11">
          <div className="hover:scale-105 cursor-pointer ">
            <Paper elevation={8} sx={{ width: 250, height: 120 }}>
              <div className="p-2">
                <CarpenterOutlined
                  fontSize="large"
                  className="bg-amber-300 text-gray-800 rounded-lg mt-[-2rem] ml-[1rem]"
                />
                <p className="text-gray-700 font-mono font-bold text-2xl text-center">
                  Wood
                </p>
                <p className="text-gray-900 text-xl"></p>
              </div>
            </Paper>
          </div>
          <div className="hover:scale-105 cursor-pointer  ">
            <Paper elevation={8} sx={{ width: 250, height: 120 }}>
              <div className="p-2 ">
                <SquareFootOutlined
                  fontSize="large"
                  className="bg-amber-300 text-gray-800 rounded-lg mt-[-2rem] ml-[1rem]"
                />
                <p className="text-gray-700 font-mono font-bold text-2xl text-center">
                  Glass
                </p>
                <p className="text-gray-900 text-xl"></p>
              </div>
            </Paper>
          </div>
          <div className="hover:scale-105 cursor-pointer  ">
            <Paper elevation={8} sx={{ width: 250, height: 120 }}>
              <div className="p-2 ">
                <HandymanOutlined
                  fontSize="large"
                  className="bg-amber-300 text-gray-800 rounded-lg mt-[-2rem] ml-[1rem]"
                />
                <p className="text-gray-700 font-bold font-mono text-2xl text-center">
                  Metal
                </p>
                <p className="text-gray-900 text-xl"></p>
              </div>
            </Paper>
          </div>
        </div>
      </div>
    </div>
  );
};

export default About;
