import React from "react";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";

const SelectOptions = ({
  value,
  options,
  name,
  label,
  errors,
  handleFormInputChange,
  noValue,
  isDisabled,
  variant,
  
}) => {

  return (
    <div>
      <FormControl fullWidth>
        <InputLabel id="select-label">{label}</InputLabel>
        <Select
          labelId="select-label"
          id={name}
          name={name}
          color="success"
          fullWidth
          disabled={isDisabled}
          value={value}
          variant={variant}
          onChange={(e) => handleFormInputChange(e)}
          label={label}
        >
          <MenuItem value={noValue}>
            <em>None</em>
          </MenuItem>
          {options?.map((item) => {
            return (
              <MenuItem key={item.id} value={item}>
                {item.name}
              </MenuItem>
            );
          })}
        </Select>
        <div className="msg error">{errors}</div>
      </FormControl>
    </div>
  );
};

export default SelectOptions;
