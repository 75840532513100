import React, { useState, useEffect } from "react";
import ModalService from "modals/service/ModalService";

const ModalRoot = () => {
  const [modal, setModal] = useState({});

  useEffect(() => {
    ModalService.on("open", ({ component, props }) => {
      setModal({
        component,
        props,
        close: (value) => {
          setModal({});
        },
      });
    });

    return () => {
      setModal({});
    };
  }, []);

  const ModalComponent = modal.component ? modal.component : null;

  return (
    <div className={ModalComponent ? "modalRoot" : ""}>
      {ModalComponent && (
        <ModalComponent
          {...modal.props}
          close={modal.close}
          className={ModalComponent ? "d-block" : ""}
        />
      )}
    </div>
  );
};

export default ModalRoot;
