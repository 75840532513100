import React from "react";
import { Grid, TextField } from "@mui/material";
import { HighlightOffRounded, ManageSearchRounded } from "@mui/icons-material";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import AppButton from "common/components/Button";
import SelectOptions from "common/components/Select";
import Service from "common/utils/Common";

const SearchForm = ({
  onSubmitSearch,
  handleSearchFormInputChange,
  searchForm,
  handleClickClear,
  setSearchForm,
  builders,
}) => {
  return (
    <>
      <form noValidate onSubmit={onSubmitSearch}>
        <Grid container spacing={2}>
          <Grid container item sx={{ my: 2 }} spacing={2}>
            <Grid item xs={6} md={3} sx={{ textAlign: "right" }}>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  sx={{}}
                  label="Date"
                  name="jobDate"
                  fullWidth
                  variant="outlined"
                  value={dayjs(searchForm.jobDate)}
                  format="YYYY-MM-DD"
                  onChange={(newVal) =>
                    setSearchForm({ ...searchForm, jobDate: newVal })
                  }
                />
              </LocalizationProvider>
            </Grid>

            {builders && (
              <Grid item xs={6} md={3}>
                <SelectOptions
                  value={
                    builders[
                      Service.getIndex(searchForm.builder.id, builders)
                    ] || ""
                  }
                  noValue={{}}
                  variant={"outlined"}
                  options={builders}
                  name="builder"
                  label="Builder Name"
                  handleFormInputChange={(e) => handleSearchFormInputChange(e)}
                ></SelectOptions>
              </Grid>
            )}
            <Grid item xs={12} md={3}>
              <TextField
                id="jobAddressId"
                label="Job Address"
                fullWidth
                color="success"
                autoComplete="off"
                variant="outlined"
                inputProps={{ maxLength: 555 }}
                name="jobAddress"
                value={searchForm.jobAddress}
                onChange={(e) => handleSearchFormInputChange(e)}
              />
            </Grid>
            <Grid item xs={12} md={3}>
              <Grid container item>
                <Grid item xs={12} sx={{ mb: 1 }}>
                  <AppButton
                    icon={<ManageSearchRounded />}
                    btnValue={"Search"}
                    size={"small"}
                    isFullwidth={true}
                    variant={"contained"}
                    type="submit"
                  ></AppButton>
                </Grid>
                <Grid item xs={12}>
                  <AppButton
                    icon={<HighlightOffRounded />}
                    btnValue={"Clear"}
                    size={"small"}
                    isFullwidth={true}
                    variant={"contained"}
                    type="button"
                    handleClicked={handleClickClear}
                  ></AppButton>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </form>
    </>
  );
};

export default SearchForm;
