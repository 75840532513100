import { NumericFormat } from "react-number-format";
import React from "react";

const NumberFormat = ({ prefix, suffix, number }) => {
  return (
    <>
      <NumericFormat
        thousandsGroupStyle="thousand"
        suffix={suffix}
        prefix={prefix}
        value={number}
        decimalSeparator="."
        displayType="text"
        type="text"
        thousandSeparator={true}
      />
    </>
  );
};

export default NumberFormat;
