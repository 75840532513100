import { Paper, TextField } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import contact from "assets/images/contact.webp";
import InputMask from "react-input-mask";
import api_service from "common/utils/Api";
import { responseErrorMsg } from "common/utils/ResponseError";
import AuthContext from "context/AuthContext";
import Error from "common/components/Error";
import AppContext from "context/AppContext";
import AlertMsg from "common/components/AlertMsg";
import { MailOutline, PhoneOutlined } from "@mui/icons-material";

const ContactUs = () => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phone: "",
    message: "",
  });

  const [errors, setErrors] = useState({});
  const { logoutUser } = useContext(AuthContext);
  const { value, setIsLoading } = useContext(AppContext);
  const [open, setOpen] = useState(false);
  const [successMsg, setSuccessMsg] = useState("");

  useEffect(() => {
    if (successMsg) setOpen(true);

    setTimeout(() => {
      setOpen(false);
    }, 4000);
  }, [successMsg]);

  const handleFormInputChange = (e) => {
    const { name, value } = e.target;

    setFormData({ ...formData, [name]: value });
  };

  const onSubmit = (e) => {
    e.preventDefault();

    sendMessage(formData);
    setFormData({
      name: "",
      email: "",
      phone: "",
      message: "",
    });
  };

  const sendMessage = async (formData) => {
    setIsLoading(true);
    try {
      const response = await api_service.sendMessage(formData);
      if (response.status === 200) {
        setIsLoading(false);
        setSuccessMsg(response.data);
      }
    } catch (error) {
      setIsLoading(false);

      setErrors(responseErrorMsg(error.response, logoutUser));
    }
  };

  const year = new Date().getFullYear();

  return (
    <div name="contact" className="w-full ">
      <div className="max-w-[1240px] m-auto min-h-screen ">
        <div className="bg-[#fff]">
          <div className="flex flex-col sm:flex-row items-center justify-between ">
            <AlertMsg
              message={successMsg}
              open={open}
              severity={errors?.message ? "error" : "success"}
            />

            <div className="w-full p-2">
              <div className="text-gray-700 font-bold text-5xl text-center py-10">
                Contact Us
              </div>

              <form noValidate onSubmit={onSubmit}>
                <TextField
                  id="name"
                  fullWidth
                  autoComplete="on"
                  label="Name"
                  color="success"
                  variant="filled"
                  name="name"
                  value={formData.name}
                  margin="normal"
                  onChange={(e) => handleFormInputChange(e)}
                />

                <Error text={errors?.name} />

                <TextField
                  id="email"
                  fullWidth
                  color="success"
                  autoComplete="on"
                  label="Email"
                  variant="filled"
                  name="email"
                  value={formData.email}
                  onChange={(e) => handleFormInputChange(e)}
                  margin="normal"
                />
                <Error text={errors?.email} />
                <InputMask
                  mask="(+1) 999 999 9999"
                  value={formData.phone}
                  maskChar={null}
                  onChange={(e) => handleFormInputChange(e)}
                >
                  {() => (
                    <TextField
                      fullWidth
                      color="success"
                      id="phone"
                      autoComplete="on"
                      label="Phone"
                      name="phone"
                      variant="filled"
                      margin="normal"
                    />
                  )}
                </InputMask>
                <Error text={errors?.phone} />
                <TextField
                  id="message"
                  fullWidth
                  multiline
                  rows={4}
                  color="success"
                  autoComplete="off"
                  label="Message"
                  value={formData.message}
                  variant="filled"
                  name="message"
                  onChange={(e) => handleFormInputChange(e)}
                  margin="normal"
                />
                <Error text={errors?.message} />
                <button
                  type="submit"
                  className="py-3 px-6 w-[100%] bg-amber-300 rounded-3xl border-amber-300 text-gray-700
               hover:text-gray-700 hover:shadow-lg hover:bg-amber-400 font-bold my-4"
                >
                  Send
                </button>
              </form>
            </div>
            <div className="md:max-w-[50vw]">
              <img loading="lazy" src={contact} alt="contact" />
            </div>
          </div>
        </div>
        <div className="p-11 flex items-center justify-center">
          <Paper elevation={16} sx={{ width: 500, paddingY: "1rem" }}>
            <p className="font-bold text-center py-4 text-gray-700 text-2xl">
              Need a quote?
            </p>
            <div className="flex flex-col sm:flex-row items-center justify-center text-stone-500">
              <p className="flex px-4 py-2 text-gray-700">
                <PhoneOutlined />
                &nbsp;(780) 773-7373
              </p>
              <p className="flex  text-sm text-gray-700  px-4 py-2">
                <MailOutline />
                &nbsp;info@arkrailings.com
              </p>
            </div>
          </Paper>
        </div>
        <div className="text-xs text-gray-500 p-2 text-center">
          <p>
            &copy; {year}  www.arkrailings.com | Developed By:{" "}
            <a href="https://www.sajjanshrestha.com" target="_blank">
              sajjanshres
            </a>
          </p>
        </div>
      </div>
    </div>
  );
};

export default ContactUs;
