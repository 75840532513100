import React, { Fragment, useContext, useEffect, useState } from "react";
import {
  Avatar,
  Box,
  Divider,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Modal,
  Paper,
  Tooltip,
  Typography,
} from "@mui/material";
import { amber, green, grey, orange, red, yellow } from "@mui/material/colors";
import Heading from "common/components/Heading";
import api_service from "common/utils/Api";
import AuthContext from "context/AuthContext";
import GenericTable from "common/components/GenericTable";
import AppContext from "context/AppContext";
import Moment from "moment";
import ModalService from "modals/service/ModalService";
import DeleteConfirmModal from "common/components/ConfirmModal";
import ModalRoot from "modals/components/ModalRoot";
import {
  DeleteForever,
  Edit,
  EditCalendar,
  Logout,
  Person,
  PersonPinCircle,
  Schedule,
} from "@mui/icons-material";
import Chart from "dashboard/components/Chart";
import NumberFormat from "common/components/NumberFormat";
import Error from "common/components/Error";
import SelectOptions from "common/components/Select";
import { responseErrorMsg } from "common/utils/ResponseError";
import CommentForm from "./components/CommentForm";
import { Link } from "react-router-dom";
import OverviewReport from "./components/OverviewReport";
import AppButton from "common/components/Button";

const Home = (props) => {
  const [comments, setComments] = useState([]);
  const { value, setIsLoading } = useContext(AppContext);
  const [username, setUsername] = useState("");
  const [stats, setStats] = useState({});
  const [donutChartDataSet, setDonutChartDataSet] = useState([]);
  const [errors, setErrors] = useState({});
  const [years, SetYears] = useState([]);
  const [defaultYear, setDefaultYear] = useState({});
  const { authTokens, user, logoutUser } = useContext(AuthContext);
  const [open, setOpen] = React.useState(false);
  const [scheduleId, setScheduleId] = useState(null);
  const [glassJobs, setGlassJobs] = useState([]);
  const [page, SetPage] = useState(0);
  const [pageSize, SetPageSize] = useState(10);

  useEffect(() => {
    const controller = new AbortController();
    const signal = controller.signal;

    getUser(signal);
    getStats(new Date().getFullYear(), signal);
    getYears();

    return () => {
      controller.abort();
    };
  }, []);

  useEffect(() => {
    const controller = new AbortController();
    const signal = controller.signal;

    getAllComments(signal);
    getGlassJobs(page, pageSize, signal);

    return () => {
      controller.abort();
    };
  }, [value]);

  const getGlassJobs = async (page, pageSize, signal) => {
    try {
      setIsLoading(true);
      const response = await api_service.getGlassJobs(
        authTokens,
        signal,
        page,
        pageSize
      );
      if (response.status === 200) {
        setIsLoading(false);
        setGlassJobs(response.data);
      }
    } catch (error) {
      setIsLoading(false);
      if (error?.name !== "CanceledError") {
        setErrors(responseErrorMsg(error.response, logoutUser));
      }
    }
  };

  const getYears = () => {
    let currentYear = new Date().getFullYear();
    let years = [];
    for (let y = 2022; y <= currentYear; y++) {
      years.push({ id: y, name: y });
    }
    SetYears(years);
    setDefaultYear(years[years.length - 1]);
  };

  const getStats = async (year, signal) => {
    try {
      setIsLoading(true);
      const response = await api_service.getStats(authTokens, year, signal);
      if (response.status === 200) {
        setIsLoading(false);
        setStats(response.data);

        let chartData = [];
        chartData.push(response.data.newJob);
        chartData.push(response.data.inProgressJob);
        chartData.push(response.data.completedJob);

        setDonutChartDataSet(chartData);
      }
    } catch (error) {
      setIsLoading(false);
      if (error?.name !== "CanceledError") {
        setErrors(responseErrorMsg(error.response, logoutUser));
      }
    }
  };

  const getUser = async (signal) => {
    try {
      setIsLoading(true);
      const response = await api_service.getUser(user, authTokens, signal);

      if (response.status === 200) {
        setIsLoading(false);
        const username = response.data.firstName + " " + response.data.lastName;
        setUsername(username);
      }
    } catch (error) {
      setIsLoading(false);
      if (error?.name !== "CanceledError") {
        setErrors(responseErrorMsg(error.response, logoutUser));
      }
    }
  };

  const getAllComments = async (signal) => {
    try {
      setIsLoading(true);
      const response = await api_service.getAllComments(authTokens, signal);
      if (response.status === 200) {
        setIsLoading(false);
        setComments(response.data);
      }
    } catch (error) {
      setIsLoading(false);
      if (error?.name !== "CanceledError") {
        setErrors(responseErrorMsg(error.response, logoutUser));
      }
    }
  };

  const handleInputChange = (e) => {
    setDefaultYear(e.target.value);
    let year = e.target.value.id;

    if (year) getStats(year);
  };

  const handleOpen = (e, item) => {
    if (item) setScheduleId(item?.id);
    else setScheduleId(null);

    setOpen(true);
  };
  const handleClose = () => setOpen(false);

  const handleJobStatusUpdate = (row, status) => {
    confirmationModal(row, status);
  };

  const handleChangePage = (e, page) => {
    getGlassJobs(page, pageSize);
  };

  const handleChangeRowsPerPage = (e) => {
    let pgSize = parseInt(e.target.value);
    SetPageSize(pgSize);
    getGlassJobs(page, pageSize);
  };

  return (
    <>
      <ModalRoot />
      <Grid
        container
        sx={{
          color: grey[900],
          bgcolor: "#FBFBFB",
        }}
      >
        <Grid item xs={6}>
          <Heading
            variant={"h3"}
            title={"Dashboard"}
            sxProp={{ fontWeight: 800, mt: 2, p: 2, color: grey[600] }}
          />
        </Grid>
        <Grid item xs={6} sx={{ textAlign: "right", p: 2 }}>
          <Typography variant="subtitle2" sx={{ pl: 2, color: grey[700] }}>
            {username}
          </Typography>
          <div>
            <Typography
            onClick={logoutUser}
              variant="caption"
              sx={{
                pl: 2,
                color: grey[700],
                fontWeight: 600,
                cursor: "pointer",
              }}
            >
              Logout&nbsp;
              <Logout sx={{ fontSize: "8pt" }} />
            </Typography>
          </div>
        </Grid>
      </Grid>

      <Error text={errors.message} />

      <Grid container spacing={1}>
        <Grid item xs={12}>
          <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box sx={style}>
              <Grid container spacing={2}>
                <Grid item xs={12} sx={{ my: 4 }}>
                  <CommentForm scheduleId={scheduleId} setOpen={setOpen} />
                </Grid>
              </Grid>
            </Box>
          </Modal>
        </Grid>
      </Grid>

      <Grid container spacing={2}>
        <Grid item xs={12} md={4} sx={{ mt: 8 }}>
          {/*<Grid item xs={12}>
               <Chart
                donutChartDataSet={donutChartDataSet}
                totalJob={stats.totalJob}
              />              
            </Grid>*/}
          <Grid item xs={12}>
            <Paper elevation={2}>
              <Typography
                variant="subtitle2"
                sx={{
                  color: grey[700],
                  fontWeight: 600,
                  p: 2,
                  textAlign: "center",
                }}
              >
                Builders [{stats?.builders?.length}]
              </Typography>

              <List className="comment-section">
                {stats.builders &&
                  stats?.builders.map((item) => (
                    <div
                      key={item.id}
                      className="flex justify-between border-b border-b-stone-200 text-gray-600 px-2 py-1"
                    >
                      <div>
                        <Link to={`/builderDetails/${item.id}`}>
                          {item.name}
                        </Link>
                      </div>

                      {item.outStandingAmt > 0 ? (
                        <div className="text-orange-500">
                          <NumberFormat
                            prefix={"$"}
                            number={item.outStandingAmt}
                          />
                        </div>
                      ) : (
                        <div>
                          <NumberFormat
                            prefix={"$"}
                            number={item.outStandingAmt}
                          />
                        </div>
                      )}
                    </div>
                  ))}
              </List>
            </Paper>
          </Grid>
        </Grid>

        <Grid item xs={12} md={4} sx={{ mt: 8 }}>
          <Grid item xs={12} sx={{ textAlign: "center", mx: 12, mb: 2 }}>
            <SelectOptions
              value={years[getIndex(defaultYear, years)] || ""}
              noValue={""}
              name="years"
              handleFormInputChange={handleInputChange}
              options={years}
              variant={"outlined"}
              isDisabled={false}
              label="Year"
              fullWidth={true}
            ></SelectOptions>
          </Grid>

          <OverviewReport
            title="Total Expense"
            amount={stats.totalExpense}
            sxProp={{
              fontWeight: 800,
              color: grey[700],
            }}
          />

          <OverviewReport
            title={"Total Revenue"}
            amount={stats.totalEarned}
            sxProp={{
              fontWeight: 800,
              color: grey[700],
            }}
          />

          <OverviewReport
            title={
              stats.netProfitLoss > 0
                ? "Net Profit"
                : stats.netProfitLoss < 0
                ? "Net Loss"
                : "No Change"
            }
            amount={stats.netProfitLoss}
            sxProp={{
              fontWeight: 800,
              color:
                stats.netProfitLoss > 0
                  ? green[500]
                  : stats.netProfitLoss < 0
                  ? red[500]
                  : grey[700],
            }}
          />

          <OverviewReport
            title={"Outstanding Amount"}
            amount={stats.totalOutstandingAtm}
            sxProp={{
              fontWeight: 800,
              color: stats.totalOutstandingAtm > 0 ? orange[500] : grey[700],
            }}
          />
        </Grid>

        <Grid item xs={12} md={4}>
          <div className="py-2">
            <AppButton
              icon={<Schedule />}
              btnValue={"Schedule Job"}
              type="button"
              size={"small"}
              variant={"contained"}
              sx={{ background: amber[100] }}
              handleClicked={handleOpen}
            ></AppButton>
          </div>

          <Paper elevation={1} sx={{ px: 2 }}>
            {comments.length > 0 ? (
              <>
                {" "}
                <List
                  className="comment-section"
                  sx={{
                    color: grey[900],
                  }}
                >
                  {comments.map((i, index) => (
                    <div key={i.id}>
                      <ListItem sx={{ m: 0, p: 0, pb: 2 }}>
                        <ListItemAvatar>
                          <Avatar
                            {...stringAvatar(
                              i.user.firstName + " " + i.user.lastName
                            )}
                          />
                        </ListItemAvatar>
                        <ListItemText
                          sx={{ color: grey[600] }}
                          primary={
                            <Fragment>
                              <div
                                style={{
                                  display: "flex",
                                  justifyContent: "space-between",
                                }}
                              >
                                <div style={{ marginTop: "10px" }}>
                                  {i.user.firstName + " " + i.user.lastName}
                                </div>
                                <div style={{ marginRight: "3px" }}>
                                  {user === i.user.email && (
                                    <>
                                      <IconButton aria-label="edit">
                                        <Tooltip title={"Edit"}>
                                          <EditCalendar
                                            fontSize="small"
                                            sx={{
                                              color: grey[600],
                                              cursor: "pointer",
                                            }}
                                            onClick={(e) => handleOpen(e, i)}
                                          />
                                        </Tooltip>
                                      </IconButton>
                                      <IconButton aria-label="delete">
                                        <Tooltip title={"Delete"}>
                                          <DeleteForever
                                            fontSize="small"
                                            sx={{
                                              color: red[300],
                                              cursor: "pointer",
                                            }}
                                            onClick={(e) =>
                                              deleteCommentModal(e, i)
                                            }
                                          />
                                        </Tooltip>
                                      </IconButton>
                                    </>
                                  )}
                                </div>
                              </div>
                            </Fragment>
                          }
                          secondary={
                            <Fragment>
                              <Typography
                                component="span"
                                variant="subtitle2"
                                sx={{ color: grey[400] }}
                              >
                                {"Created: "}
                                {Moment(i.createdDate).format(
                                  "DD MMM YYYY,  hh:mm a"
                                )}
                              </Typography>
                              <br></br>
                              <span className="p-2 text-orange-600 text-sm">
                                {Moment(i.date).format("DD MMM YYYY")}
                              </span>
                              <br></br>
                              <span className="px-2 text-sm text-gray-600">
                                {" "}
                                {i.comment}
                              </span>
                            </Fragment>
                          }
                        />
                      </ListItem>
                      <Divider variant="inset" />
                    </div>
                  ))}
                </List>
              </>
            ) : (
              <Typography variant="subtitle1" sx={{ m: 2, color: grey[500] }}>
                No Data Available
              </Typography>
            )}
          </Paper>
        </Grid>
      </Grid>

      <Grid container spacing={2}>
        <Grid item xs={12} sx={{ mt: 10 }}>
          <Heading
            title={"Glass Jobs [" + glassJobs?.totalElements + "]"}
            variant={"h6"}
            sxProp={{
              color: grey[700],
              fontWeight: 600,
              p: 2,
            }}
          />
        </Grid>
        <Grid item xs={12} sx={{ m: 2 }}>
          <GenericTable
            tableHeaders={[
              "Date",
              "Address",
              "Builder",
              "Amount",
              "Installer",
              "Status",
            ]}
            showSN={false}
            tableData={glassJobs.content}
            dataAttribute={[
              "jobDate",
              "jobAddress",
              "builder",
              "glassExpense",
              "installers",
            ]}
            handleJobStatusUpdate={handleJobStatusUpdate}
            page={page}
            pageSize={pageSize}
            isGlassTable={true}
            totalElements={glassJobs.totalElements}
            handleChangePage={handleChangePage}
            handleChangeRowsPerPage={handleChangeRowsPerPage}
          />
        </Grid>
      </Grid>
      <Grid container spacing={2} sx={{ mt: 5 }}>
        <Grid item xs={12}>
          <Heading
            title={"Overview"}
            variant={"h6"}
            sxProp={{
              color: grey[700],
              fontWeight: 600,
              p: 2,
            }}
          />
        </Grid>
      </Grid>

      <Grid container spacing={2}>
        <Grid item xs={12} sm={6} md={6} sx={{ mt: 10 }}>
          <GenericTable
            tableHeaders={[
              "Month",
              "No. of Job",
              "Expenses",
              "Earned",
              "Profit/Lost",
            ]}
            showSN={false}
            tableData={stats.monthlyStats}
            dataAttribute={[
              "month",
              "noOfJobs",
              "totalExpense",
              "totalEarned",
              "profitLossAtm",
            ]}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={6} sx={{ mt: 10 }}>
          <GenericTable
            tableHeaders={[
              "Year",
              "No. of Job",
              "Expenses",
              "Earned",
              "Profit/Lost",
            ]}
            showSN={false}
            tableData={stats.yearlyStats}
            dataAttribute={[
              "year",
              "noOfJobs",
              "totalExpense",
              "totalEarned",
              "profitLossAtm",
            ]}
          />
        </Grid>
      </Grid>
    </>
  );
};

export default Home;

function getIndex(yearId, arr) {
  for (let i = 0; i < arr.length; i++) {
    if (arr[i] === yearId) {
      return i;
    }
  }
  return "";
}

const confirmationModal = (row, status) => {
  let jobIds = [];
  jobIds.push(row.jobId);
  const props = {
    message: "You are about to change job status to:",
    placeholder:
      status === "I"
        ? "Under Production"
        : status === "W"
        ? "At Warehouse"
        : "Completed",
    jobIds: jobIds,
    type: "confirmation",
    status: status,
    title: "Confirmation",
  };
  ModalService.open(DeleteConfirmModal, props);
};

const deleteCommentModal = (e, comment) => {
  const props = {
    message: "Are you sure you want to delete comment ID ",
    placeholder: comment.id,
    commentId: comment.id,
    type: "comment",
    title: "Delete Comment Confirmation",
  };
  ModalService.open(DeleteConfirmModal, props);
};

function stringToColor(string) {
  let hash = 0;
  let i;

  /* eslint-disable no-bitwise */
  for (i = 0; i < string.length; i += 1) {
    hash = string.charCodeAt(i) + ((hash << 5) - hash);
  }

  let color = "#";

  for (i = 0; i < 3; i += 1) {
    const value = (hash >> (i * 8)) & 0xff;
    color += `00${value.toString(16)}`.slice(-2);
  }
  /* eslint-enable no-bitwise */

  return color;
}

function stringAvatar(name) {
  return {
    sx: {
      bgcolor: stringToColor(name),
    },
    children: `${name.split(" ")[0][0]}${name.split(" ")[1][0]}`,
  };
}

const style = {
  position: "absolute",
  top: "30%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #616161",
  boxShadow: 24,
  p: 4,
  borderRadius: 1,
};
